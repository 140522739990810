import { css } from "styled-components";

export const HideHorizontalScrollBar = () => css`
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	/* Hide scrollbar for Chrome, Safari and Opera */
	::-webkit-scrollbar {
		width: 1px;
		display: none;
	}
`;
