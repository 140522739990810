/* eslint-disable @typescript-eslint/camelcase */

export type AppThemeColors =
	| 'black'
	| 'light_black'
	| 'steel'
	| 'gray'
	| 'light_gray'
	| 'red'
	| 'blue'
	| 'white'
	| 'transparent'
	| 'placeholder'
	| 'overlay';

export type AppThemeTypes = {
	colors: AppThemeColors;
};

export const AppTheme = {
	colors: {
		black: '#242424',
		light_black: '#4b4b4b',
		steel: '#8a8a8e',
		gray: '#a0a0a0',
		light_gray: 'rgb(236, 238, 239)',
		red: '#ff3b30',
		blue: '#0e6bd6',
		white: '#fff',
		transparent: 'rgba(118, 118, 128, 0.24)',
		placeholder: 'rgba(235, 235, 245, 0.6)',
		overlay: 'rgb(21, 21, 21, 82%)',
	},
	background_color: {},
	screen_sizes: {
		s: '414px',
		m: '768px',
		l: '1024px',
		xl: '1266px',
	},
	radius: '8px',
	rounded: '20px',
	input_height: '1em',
	shadow: {
		overlay: '1px 16px 25px 0px rgba(128,128,128,0.5)',
		small: '0px 0px 2px 1px rgba(128,128,128,0.5)',
	},
};
