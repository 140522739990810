import { Dish, Drink } from "Types";

export const filterDrinksByRestaurant = (
  clientType: string,
  restaurantId: string,
  items: Drink[],
) => {
  if (clientType !== "hotel") return items;
  if (!items || !items.length)  return [];

  const filteredItemsByRestaurant: Drink[] = items.filter((item: Drink) => !item.restaurants.length || item.restaurants.some((restaurant: any) => restaurant._id === restaurantId));
  return filteredItemsByRestaurant;
};

export const filterDishesByRestaurant = (
  clientType: string,
  restaurantId: string,
  items: Dish[],
) => {
  if (clientType !== "hotel") return items;
  if (!items || !items.length) return [];

  const filteredItemsByRestaurant: Dish[] = items.filter((item: Dish) => !item.restaurants.length || item.restaurants.some((restaurant: any) => restaurant._id === restaurantId));
  return filteredItemsByRestaurant;
};
