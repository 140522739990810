import React from "react";
import styled from "styled-components";

const Link = styled.a<any>`
	text-align: center;
  font-family: CenturyGothicBold;
	font-size: ${({ size = "1rem" }) => size};
	color: ${({ theme }) => theme.colors.white};
`;

const NavLink: React.FC<any> = ({ children, size, href }) => {
	return (
    <Link href={href} size={size} rel="noopener noreferrer" target="_blank">{children}</Link>
  );
};

export default NavLink;
