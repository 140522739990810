import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
//import cookie from 'react-cookies';

import { DrinksService } from '../Services/Drinks';
import { Drink } from '../Types';


export interface DrinksState {
  data: Drink[] | null;
  error: string | null;
};

const InitialDrinksState = {
  data: null,
  error: null
} as DrinksState;

export interface IgetDrinksData {
  clientId: string;
  page: number;
  filters: any;
  restaurantId: string;
}
/* GET CLIENT INFO THUNK  */
export const getDrinksData = createAsyncThunk<any,IgetDrinksData,{}>( 'drinks/getDrinksData',
  async (payload) =>  {
    const { clientId, page, filters, restaurantId } = payload;
    const response = await DrinksService.fetchDrinks(clientId, page, filters, restaurantId);
    return response;
  }
);

/* REDUCER CREATOR */
const drinksSlice = createSlice({
  name: 'drinks',
  initialState: InitialDrinksState,
  reducers: {
    setDrinks: (state, action: PayloadAction<DrinksState>) => {
      return {...action.payload};
    },
    resetDrinks: (state) => {
      return {...InitialDrinksState}
    },
  },
  extraReducers: {
    [getDrinksData.pending.type]: (state, action) => {
      return InitialDrinksState;
    },
    [getDrinksData.fulfilled.type]: (state, action) => {
      state.data = action.payload;
      state.error = null;
    },
    [getDrinksData.rejected.type]: (state, action) => {
      return InitialDrinksState;
    },
  }
});

/* ACTION TYPES EXPORTS */
export const {
  setDrinks: setDrinksActionCreator,
  resetDrinks: resetDrinksActionCreator,
} = drinksSlice.actions;

export default drinksSlice;