import { createSlice, createAsyncThunk, PayloadAction, Dispatch } from "@reduxjs/toolkit";
//import cookie from 'react-cookies';

import { WinesService } from "../Services/Wine";
import { Wine } from "../Types";
import { AppState } from "store";
import { isUsingCellarFilters } from "Utils";
import { rebuildDynamicFilters } from "Hooks/useFetchWines";

export interface WinesState {
	data: Wine[] | null;
	page: number;
	error: string | null;
	winesTotal: number;
	cellarLength?: number | null;
	status: "Unset" | "Loading" | "Ok" | "Failed";
}

const InitialWinesState = {
	data: null,
	page: 1,
	error: null,
	winesTotal: 0,
	cellarLength: null,
	status: "Unset",
} as WinesState;

/* GET CLIENT INFO THUNK  */
export interface IgetWinesData {
	clientId: string;
	page: number;
	filters: any;
	language: string;
	winesFilter?: any;
}
export const getRestaurantCellar = createAsyncThunk<any, IgetWinesData, {state: AppState, dispatch: Dispatch}>("wines/getWinesData",
		async (payload, {getState, dispatch}) => {
		const { clientId, page, filters, language, winesFilter } = payload;
		const selections = getState().selections;
		const { wine } = getState().categories;
		const client = getState().client;
		const isUsingFilters = isUsingCellarFilters(winesFilter);

		const response = await WinesService.fetchWines(clientId, page, filters, language, 5000);
		let wines = response.wines;
		let response_number = wines.length;
		// * Processing wines by selection (if this filter is used)
		let parsedList = wines;
		if (winesFilter.selection) {
			const currentSelection = selections.data.find(({ label }: any) => label === winesFilter.selection);
			parsedList = parsedList.filter(({ vino_local_id }: any) => currentSelection.wines.includes(vino_local_id));
		}

		// * Building new filter options
		if (isUsingFilters) {
			rebuildDynamicFilters(parsedList, wine, dispatch);
		}

		// * Final processing new state on Redux Store
		return {
			wines: parsedList,
			page,
			error: null,
			winesTotal: isUsingFilters ? parsedList.length : response_number,
			cellarLength: client.data?.total_cava || response_number,
		};
});

export const getWineDetail = createAsyncThunk<any, IgetWinesData, {}>("wines/getWineDetail", async payload => {
	const { clientId, page, filters, language } = payload;
	const response = await WinesService.fetchWines(clientId, page, filters, language);
	return response.wines;
});

/* REDUCER CREATOR */
const winesSlice = createSlice({
	name: "wines",
	initialState: InitialWinesState,
	reducers: {
		setWines: (state: WinesState, action: PayloadAction<any>): void => {
			state.data = action.payload.data;
			state.winesTotal = action.payload.winesTotal ?? 0;
			state.error = action.payload.error;
			state.cellarLength = action.payload.cellarLength || action.payload.winesTotal;
		},
		resetWines: (): WinesState => {
			return { ...InitialWinesState };
		},
		increasePage: (state: WinesState): void => {
			state.page = state.page + 1;
		},
		resetPage: (state: WinesState): void => {
			state.page = 1;
		},
	},
	extraReducers: {
		[getRestaurantCellar.pending.type]: (): WinesState => {
			return {
				...InitialWinesState,
				status: "Loading",
			};
		},
		[getRestaurantCellar.fulfilled.type]: (state: WinesState, action: any): void => {
			state.data = action.payload.wines;
			state.winesTotal = action.payload.winesTotal ?? 0;
			state.cellarLength = action.payload.cellarLength || action.payload.winesTotal;
			state.error = null;
			state.status = "Ok";
		},
		[getRestaurantCellar.rejected.type]: (): WinesState => {
			return {
				...InitialWinesState,
				status: "Failed",
			};
		},
	},
});

/* ACTION TYPES EXPORTS */
export const {
	setWines: setWinesActionCreator,
	resetWines: resetWinesActionCreator,
	increasePage: increasePageActionCreator,
	resetPage: resetPageActionCreator,
} = winesSlice.actions;

export default winesSlice;
