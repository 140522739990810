import axios from "axios";
import { AUTHORIZATION, URL_INTEGRAL } from "env";

const SERVICE_ID = "@ClientService";
const DEBUGGING = false;

const fetchClientData = async (id: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchClientData`);
	const CONFIG = { headers: { authorization: `${AUTHORIZATION}` } };

	try {
		DEBUGGING && console.log({ params: { id } });

		const response = await axios.get(`${URL_INTEGRAL}/clients/restaurants/${id}`, CONFIG);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();
		return response.data;
	} catch (e) {
		DEBUGGING && console.error(`${SERVICE_ID}: `, e);
		DEBUGGING && console.groupEnd();
		return e;
	}
};

const fetchClientDataByName = async (id: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchClientDataByName`);
	const CONFIG = { headers: { authorization: `${AUTHORIZATION}` } };

	try {
		DEBUGGING && console.log({ params: { id } });

		const response = await axios.get(`${URL_INTEGRAL}/clients/${id}`, CONFIG);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();
		return response.data;
	} catch (e) {
		DEBUGGING && console.error(`${SERVICE_ID}: `, e);
		DEBUGGING && console.groupEnd();
		return e;
	}
};

export const ClientService = {
	fetchClientData,
	fetchClientDataByName,
};
