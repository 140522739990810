import { useLocation } from "react-router-dom";
import { ACTION_BAR_TYPES } from "Types";

const useProcessQueryParams = () => {
  // Retrieve query params if exists, this enables the Go Back button to a external page, disable manually Menu option and define home (init page)
  const { search } = useLocation(); // search contains optional query params
  const queryParams = new URLSearchParams(search);

   // If "home" query param is defined, we verify if it of type ActionBarType, if not we set "restaurant" as default
  const home: string = queryParams.get("home") || ACTION_BAR_TYPES.RESTAURANT;
  const homePage: string =
    home === ACTION_BAR_TYPES.RESTAURANT ||
    home === ACTION_BAR_TYPES.CELLAR ||
    home === ACTION_BAR_TYPES.MENU ||
    home === ACTION_BAR_TYPES.DRINKS
      ? home
      : ACTION_BAR_TYPES.RESTAURANT;

  // If "menu" query param is defined, we verify if it set as "false" to disable Menu view
  const menu = queryParams.get("menu");
  const isMenuAvailable: boolean = menu ? menu === "true" : true;
  
  // If "restaurant" query param is defined, we verify if it set as "false" to disable Restaurant Details view
  const restaurant = queryParams.get("restaurant");
  const isRestaurantDetailsAvailable: boolean = restaurant ? restaurant === "true" : true;
  
  // If "ref" query param is defined, it is set as URL to redirect when "Go Back" button is clicked
  const sourceURL: string = queryParams.get("ref") || "";

  const goBackOriginPage = (url: string) => {
    return () => (window.location.href = url);
  };
  return { search, sourceURL, isMenuAvailable, isRestaurantDetailsAvailable, homePage, goBackOriginPage };
};

export default useProcessQueryParams;
