import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { flex } from "Styles/tools";
import CloseIconButton from "../CloseIcon";
import { Logo, SocialBanner } from "../";
import { Button, NavLink, Subtitle } from "Components/Core";
import { useTranslation } from "react-i18next";
import { APP_VERSION } from "env";
import { useSelector } from "react-redux";
import { AppState } from "store";

const Overlay = styled(motion.div)`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	padding: 25px 16px;
	background-color: ${({ theme }): any => theme.colors.overlay};
	${flex("column", "flex-start", "flex-start", "nowrap")}
	overflow-y: scroll;
`;

const ModalContent = styled.div`
	${flex("column", "center", "center", "nowrap")}
	margin: 2em 0;
	flex-grow: 1;
	width: 100%;
`;

const IconContainer = styled.div`
	margin-left: auto;
`;

const About: React.FC<any> = ({ isOpen, close }) => {
	const { t } = useTranslation();
	const clientName = useSelector((store: AppState) => store.client.data?.clientid);
	const navigator = window.navigator as any;

	const sharePage = (): void => {
		if (navigator.share) {
			navigator.share({
				title: "WineAdvisor",
				text: t("common.shareText"),
				url: `https://app.wineadvisor.mx/${clientName}`,
			});
		}
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<Overlay
					className="overlay"
					initial={{ x: "-100%" }}
					animate={{ x: 0 }}
					exit={{ x: "-100%" }}
					transition={{ duration: 0.3 }}
				>
					<IconContainer>
						<CloseIconButton onClick={close} />
					</IconContainer>
					<ModalContent className="modal-content">
						<Logo white />
						<SocialBanner color="white" />
						<Subtitle align="center" color="white" size="1rem">
							{`v${APP_VERSION}`}
						</Subtitle>
						<NavLink href={"https://wineadvisor.mx/terms-&-privacy"}>{t("common.privacyNotice")}</NavLink>
						{navigator.share && (
							<Button margin="2rem 0 1rem 0" onClick={sharePage}>
								{t("common.share")}
							</Button>
						)}
					</ModalContent>
				</Overlay>
			)}
		</AnimatePresence>,
		document.getElementById("portal") as Element
	);
};

export default About;
