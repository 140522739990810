import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaYoutube, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';

const getIcon = (key: string, color = 'black', size = 30) => {
	switch (key) {
		case 'facebook':
			return <FaFacebook color={color} size={size} />;
		case 'instagram':
			return <FaInstagram color={color} size={size} />;
		case 'twitter':
			return <FaTwitter color={color} size={size} />;
		case 'youtube':
			return <FaYoutube color={color} size={size} />;
		case 'linkedin':
			return <FaLinkedin color={color} size={size} />;
		case 'website':
			return <CgWebsite color={color} size={size} />;
	}
};

const SOCIAL_LINKS = [
	{
		key: 'facebook',
		url: 'https://www.facebook.com/pg/WineAdvisor',
	},
	{
		key: 'instagram',
		url: 'https://www.instagram.com/wine.advisor',
	},
	{
		key: 'twitter',
		url: 'https://twitter.com/wineadvisormx',
	},
	{
		key: 'linkedin',
		url: 'https://www.linkedin.com/company/intelligent-restaurants-de-mexico-s-a-de-c-v-',
	},
	{
		key: 'website',
		url: 'https://wineadvisor.mx',
	},
];

const SocialBannerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin: 2rem 1rem;
	width: 20rem;
`;

const Button = styled.a``;

const SocialBanner: React.FC<any> = ({ color, sizeIcons }) => {
	return (
		<SocialBannerContainer>
			{SOCIAL_LINKS.map(({ key, url }, index) => (
				<Button href={url} key={index} target="_blank">
					{getIcon(key, color, sizeIcons)}
				</Button>
			))}
		</SocialBannerContainer>
	);
};

export default SocialBanner;
