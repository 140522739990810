import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Dish, Wine } from 'Types';
import { flex } from 'Styles/tools';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from 'Components/Shared';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { formatCurrencyAmount } from 'Utils';
import  { GiWineGlass } from 'react-icons/gi'

const DishListItemContainer = styled.div`
  ${ flex('column', 'flex-start', 'baseline', 'nowrap')}
  width: 100%;
`;

const DishName = styled.h4`
  color: ${({theme}) => theme.colors.black};
`;

const DishPrice = styled.h5`
  color: ${({theme}) => theme.colors.blue};
`;

const DishPairing = styled.h5`
  ${flex("row", "flex-start", "center", "nowrap")}
  color: ${({theme}) => theme.colors.red};
  font-size: 0.9em;
`;

const DishDescription = styled.h5`
  color: ${({theme}) => theme.colors.gray};
  font-size: 0.8em;
`;

interface IDishListItem {
  dish: Dish;
};

const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;

const DishListItem: React.FC<IDishListItem> = ({dish}: IDishListItem) => {
  const { t, i18n } = useTranslation();
  const [currentLang, ] = i18n.language.split('-');
  const wines = useSelector((store: AppState) => store.wines.data);
  const client = useSelector((state: AppState) => state.client.data);
  const [pairedWines, setPairedWines] = useState<Wine[]>([]);
  const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;


  useEffect(() => {
    wines &&
      setPairedWines(
        wines.filter(
          (wine: Wine) => !wine.outOfStock && dish.wines?.includes(wine.id)
        )
      );
  }, [dish.wines, wines]);  

  return (
		<DishListItemContainer className="dish-list-item-container">
			{/* Title */}
			<DishName className="dish-name">{dish[`dish_name_${currentLang}` as keyof typeof dish] || t("common.noInfoAvailableInThisLanguage")}</DishName>
			{/* Description */}
			{dish[`dish_desc_${currentLang}` as keyof typeof dish] && (
				<FlexContainer width="100%" margin="0.5em 0 0 0" className="dish-description-row">
					<FlexContainer className="dish-description">
						<DishDescription>{`${dish[`dish_desc_${currentLang}` as keyof typeof dish]}`}</DishDescription>
					</FlexContainer>
				</FlexContainer>
			)}
			{/* Pairings */}
			<FlexContainer width="100%" margin="0.5em 0" className="dish-pairing-row">
				<FlexContainer className="dish-pairing">
					{pairedWines.length !== 0 && (
						<DishPairing>
							<GiWineGlass style={{ marginRight: "2px" }} />
							{`${t("menuView.pairingsWith")} ${pairedWines.length} ${t("common.labels")}`}
						</DishPairing>
					)}
				</FlexContainer>
			</FlexContainer>
			<FlexContainer width="100%" margin="0 0 0.5em 0" className="dish-price-row">
				<FlexContainer margin="0 0.6em 0 0" className="dish-price">
					{dish.dish_price !== null && dish.dish_price !== 0 && (
						<DishPrice>{`${formatCurrencyAmount(dish.dish_price, client?.main_currency, decimals)}`}</DishPrice>
					)}
				</FlexContainer>
			</FlexContainer>
		</DishListItemContainer>
	);
}

export default DishListItem
