import React from 'react';
import styled from 'styled-components';

import { Drink } from 'Types';
import { useTranslation } from 'react-i18next';
import { flex } from 'Styles/tools';
import PageContent from 'Components/Shared/Page/PageContent';
import { Description } from 'Components/Core';
import { Page, PageHeader, Title, FlexContainer, Image } from 'Components/Shared';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { formatCurrencyAmount } from 'Utils';

import DEFAULT_IMAGE_DRINK from "Assets/icons/drink.png";


const DescriptionBox = styled.div`
  ${ flex('row', 'space-between', 'baseline', 'nowrap') }
  padding: 10px 0;
  width: 100%;
  border-bottom: 1px solid ${ ({theme}) => theme.colors.transparent };
`;

const Label = styled.span`
`;

const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;

interface IDrinkDetailPageProps {
  close: () => void;
  drink: Drink | null;
}
const DrinkDetailPage: React.FC<IDrinkDetailPageProps> = ({drink, close}: IDrinkDetailPageProps) => {
  const { t , i18n } = useTranslation();

  const client = useSelector((state: AppState) => state.client.data);
  
  const currentLang = i18n.language.split('-')[0].toUpperCase();
  const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;
  
  return (
    <Page>
      <PageHeader 
        color='black'
        textColor='white'
        backEnabled={true}
        backText={t('common.return')}
        customBackAction={close}
      />
      <PageContent>
        {/* Drink detail description */}
        <FlexContainer 
          width='100%'
          padding={drink?.img_url ? '0 1em' : '2em 1em'}
          direction='column'
          justify='center'
          align='center'
        >
          {/* Image */}
          {drink?.img_url ? (
              <Image id={drink._id} srcImg={drink.img_url} width="45vw" height="28vw" />
            ) : (
              <Image id={"def"} srcImg={DEFAULT_IMAGE_DRINK} height='15vh' width='15vw' />
            )}
          {/* Name */}
          <Title className='bold' color='black' margin='1.6em auto'>{drink ? drink[`name${currentLang}` as keyof typeof drink] || t("common.noInfoAvailableInThisLanguage") : "--"}</Title>
          {/* Detail description */}
          <Description color='gray' margin='0 0 2rem 0'>
            {drink ? drink[`desc${currentLang}`as keyof typeof drink] || t("common.noInfoAvailableInThisLanguage") : "--"}
          </Description>
          {/* Volumen */}
          {Number(drink ? drink.volume : 0) !== 0 && (
            <DescriptionBox className='description-box'>
              <Label>{t(`drinkDetail.volume`)}</Label>
              <Label className='bold'>{`${drink ? drink.volume : 0} ${drink ? drink.unit : 0}`}</Label>
            </DescriptionBox>
          )}
          {/* Price */}
          {drink?.price !== null && drink?.price !== 0 && (
            <DescriptionBox className='description-box-price'>
              <Label>{t(`drinkDetail.price`)}</Label>
              <Label className='bold'>{`${drink ? formatCurrencyAmount(drink.price, client?.main_currency, decimals): "0.00"}`}</Label>
            </DescriptionBox>
          )}
        </FlexContainer>
      </PageContent>
    </Page>
  )
}

export default DrinkDetailPage;
