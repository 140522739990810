import React, { ReactNode } from "react";
import styled from "styled-components";

import { AppThemeColors } from "Styles/Theme";

interface Titlecontainer {
	align?: string;
	color?: AppThemeColors;
	margin?: string;
	padding?: string;
	size?: string;
	bold?: string;
	wrap?: string;
}

interface TitleProps extends Titlecontainer {
	children: ReactNode;
}

const SubtitleContainer = styled.h3<Titlecontainer>`
	text-align: ${({ align = "left" }): any => align};
	margin: ${({ margin = "1rem 0" }): any => margin};
	padding: ${({ padding }): any => padding};
	font-size: ${({ size = "1.25rem" }): any => size};
	font-weight: ${({ bold = "600" }): any => bold};
	color: ${({ theme, color }): any => (color ? theme.colors[color] : theme.colors.black)};
	overflow-wrap: ${({ wrap = "normal" }): any => wrap};
	@media (prefers-color-scheme: dark) {
		color: ${({ theme, color }): any => (color ? theme.colors[color] : theme.colors.white)};
	}
`;

const Subtitle: React.FC<TitleProps> = ({ align, children, color, margin, padding, size, bold, wrap }) => {
	return (
		<SubtitleContainer
			align={align}
			color={color}
			margin={margin}
			padding={padding}
			size={size}
			bold={bold}
			wrap={wrap}>
			{children}
		</SubtitleContainer>
	);
};

export default Subtitle;
