import styled from 'styled-components';

type PageContentType = {
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
};

const PageContent = styled.div<PageContentType>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: ${ ({width = '100%'}) => width };
  height: ${ ({height = 'auto'}) => height };
  margin: ${ ({margin = ''}) => margin };
  padding: ${ ({padding = ''}) => padding };
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default PageContent;
