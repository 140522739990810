import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import clientSlice, { ClientState } from "./Reducers/Client";
import winesSlice, { WinesState } from "./Reducers/Wines";
import dishesSlice, { DishesState } from "./Reducers/Dishes";
import drinksSlice, { DrinksState } from "./Reducers/Drinks";
import filtersSlice, { FiltersState } from "./Reducers/Filters";
import categoriesSlice, { CategoriesState } from "./Reducers/Categories";
import SelectionsSlice, { SelectionsState } from "Reducers/Selections";

const reducer = {
	categories: categoriesSlice.reducer,
	client: clientSlice.reducer,
	dishes: dishesSlice.reducer,
	drinks: drinksSlice.reducer,
	filters: filtersSlice.reducer,
	selections: SelectionsSlice.reducer,
	wines: winesSlice.reducer,
};

const store = configureStore({
	reducer,
	middleware: [
		...getDefaultMiddleware({
			immutableCheck: false, // to disable the SerializableStateInvariantMiddleware
		}),
	],
});

export interface AppState {
	categories: CategoriesState;
	client: ClientState;
	dishes: DishesState;
	drinks: DrinksState;
	filters: FiltersState;
	selections: SelectionsState;
	wines: WinesState;
}

export default store;
export type RootState = ReturnType<typeof store.getState>;
