import styled from 'styled-components';
import { motion } from 'framer-motion';

type FlexDirection = 'column' | 'row';
type FlexJustify = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around';
type FlexAlign = 'center' | 'flex-start' | 'flex-end';
type FlexWrap = 'wrap' | 'nowrap';

interface FlexContainerProps {
  direction?: FlexDirection;
  justify?: FlexJustify;
  align?: FlexAlign;
  wrap?: FlexWrap;
  shrink?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
}

const FlexContainer = styled(motion.div)<FlexContainerProps>`
  display: flex;
  flex-direction: ${ ({direction = 'row'}) => direction };
  justify-content: ${ ({justify = 'flex-start'}) => justify };
  align-items: ${ ({align = 'flex-start'}) => align };
  flex-wrap: ${ ({wrap = 'nowrap'}) => wrap };
  flex-shrink: ${({shrink = ''}) => shrink };

  width: ${ ({width = 'fit-content'}) => width };
  height: ${ ({height = 'auto'}) => height };
  margin: ${ ({margin = '0'}) => margin };
  padding: ${ ({padding = '0'}) => padding };
`

export default FlexContainer;