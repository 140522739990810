import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n.use(LanguageDetector).use(Backend).use(initReactI18next).init({
  debug: false,
  detection: {
    order: ['navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['localStorage', 'cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'es',
});

export default i18n;


