import React from "react";
import { ListItem } from "../../../../Components/Shared";
import FilterSelector from "./FilterSelector";
import { AppTheme } from "../../../../Styles/Theme";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { useTranslation } from "react-i18next";
import { GlassIcon } from "../../../../Components/Shared/icons";
import { ICellarFilters } from "../../../../Reducers/Filters";
import { getGlobalLanguage } from "Utils/language.helper";
import { ASYNC_FAILED, ASYNC_LOADING, ASYNC_SUCCEEDED } from "Utils/constants/thunks";

interface FilterItemProps {
	filter: { label: string; key: string };
	onClick: (name: string, value: string) => void;
}

const GLASS_LABEL_LANG = {
	ES: "Copa",
	EN: "Glass",
	PT: "Copo",
};

const FilterItem: React.FC<FilterItemProps> = ({ filter, onClick }) => {
	const categories = useSelector((state: AppState) => state.categories.wine);
	const selections = useSelector((state: AppState) => state.selections);
	const { cellar } = useSelector((state: AppState) => state.filters);
	const { t, i18n } = useTranslation();
	const currenLang = getGlobalLanguage(i18n.language).toUpperCase();
	const currenLangGlassLabel: string = GLASS_LABEL_LANG[currenLang as keyof typeof GLASS_LABEL_LANG];

	const optionsList: string[] = categories
		? (categories[`${filter.key}${currenLang}` as keyof typeof categories] as string[])
		: [];

	const selectionOptions: any[] = selections.data
		? selections.data.map((selection: any) => ({
			text: selection[`title_${currenLang.toLocaleLowerCase()}`] ?? "",
			value: selection.label
		}))
		: [];

	if (filter.key === "selection") {
		return (
			<FilterSelector title={filter.label}>
				{selections.status === ASYNC_LOADING &&
					(!selectionOptions ||
						(selectionOptions.length === 0 && (
							<ListItem color="placeholder" hideIcon={true}>
								<h6 style={{ color: AppTheme.colors.white }}>{`${t("common.loading")}...`}</h6>
							</ListItem>
						)))}
				{selections.status === ASYNC_FAILED &&
					(<ListItem color="placeholder" hideIcon={true}>
						<h6 style={{ color: AppTheme.colors.white }}>{`${t("common.noDataFound")}...`}</h6>
					</ListItem>
					)}
				{selections.status === ASYNC_SUCCEEDED &&
					(!selectionOptions ||
						(selectionOptions.length === 0 && (
							<ListItem color="placeholder" hideIcon={true}>
								<h6 style={{ color: AppTheme.colors.white }}>{t("common.noOptionsAvailable")}</h6>
							</ListItem>
						)))}
				{selections.status === ASYNC_SUCCEEDED &&
					selectionOptions &&
					selectionOptions.length > 0 &&
					selectionOptions.map((selection: any, index: number) => (
						<ListItem color="placeholder" key={index} onClick={(): void => onClick(filter.key, selection.value)} hideIcon={true}>
							<h6
								style={{
									color:
										cellar[filter.key as keyof ICellarFilters] !== selection.value ? AppTheme.colors.white : AppTheme.colors.red,
								}}>
								{selection.text.length > 0 ? selection.text : t("common.noInfoAvailableInThisLanguage")}
							</h6>
						</ListItem>
					))}
			</FilterSelector>
		);
	}

	if (filter.key === "selling_by") {
		return (
			<ListItem
				color="placeholder"
				key="by-class"
				padding="10px 7px"
				justify="flex-start"
				onClick={(): void => onClick(filter.key, currenLangGlassLabel)}
				hideIcon={true}>
				<GlassIcon color={"red"} />
				<h4
					style={{
						color: cellar.selling_by !== currenLangGlassLabel ? AppTheme.colors.white : AppTheme.colors.red,
						fontFamily: "CenturyGothicBold",
					}}>
					{t("wineDetail.glass")}
				</h4>
			</ListItem>
		);
	}

	return (
		<FilterSelector title={filter.label}>
			{!optionsList ||
				(optionsList.length === 0 && (
					<ListItem color="placeholder" hideIcon={true}>
						<h6 style={{ color: AppTheme.colors.white }}>{t("common.noOptionsAvailable")}</h6>
					</ListItem>
				))}
			{optionsList &&
				optionsList.length > 0 &&
				optionsList.map((option: string, index: number) => (
					<ListItem color="placeholder" key={index} onClick={(): void => onClick(filter.key, option)} hideIcon={true}>
						<h6
							style={{
								color:
									cellar[filter.key as keyof ICellarFilters] !== option ? AppTheme.colors.white : AppTheme.colors.red,
							}}>
							{option || t("common.noInfoAvailableInThisLanguage")}
						</h6>
					</ListItem>
				))}
		</FilterSelector>
	);
};

export default FilterItem;
