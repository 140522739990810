import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const CloseIcon = styled(MdClose)`
  color: ${ ({theme}) => theme.colors.red };
  font-size: 2em;
  flex-grow: 0;
`
interface ICloseIconButtonProps {
  onClick: () => void
}
const CloseIconButton: React.FC<ICloseIconButtonProps> = ({onClick}: ICloseIconButtonProps) => {
  return (
    <CloseIcon onClick={onClick}/>
  )
}

export default CloseIconButton
