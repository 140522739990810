import { createGlobalStyle, css } from "styled-components";

/* const centuryFont = '../assets/fonts/CenturyGothic.ttf';
const centuryBoldFont = '../assets/fonts/CenturyGothicBold.ttf'; */

export const GlobalStyle = createGlobalStyle(
	props => css`
		html {
			scroll-behaviour: smooth;
		}

		body {
			scroll-behaviour: smooth;
			margin: 0;
			padding: 0;
			width: 100%;
			max-width: 500px;
			max-width: 500px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			background-color: black;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			span,
			a,
			div,
			section,
			input,
			textarea,
			button,
			img ul,
			li,
			ol {
				margin: 0;
				padding: 0;
				text-decoration: none;
				list-style: none;
				box-sizing: border-box;
			}

			* {
				font-family: "CenturyGothic";
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			span,
			button,
			a {
				color: ${({ theme }) => theme.colors.dark};
				&.bold {
					font-family: "CenturyGothicBold";
				}
				&.light {
					color: ${({ theme }) => theme.colors.light};
				}
				&.med {
					color: ${({ theme }) => theme.colors.med};
				}
				&.accent-red {
					color: ${({ theme }) => theme.colors.red};
				}
				&.accent-blue {
					color: ${({ theme }) => theme.colors.blue};
				}
			}

			h1 {
				font-size: 1.4rem;
			}
			h2 {
				font-size: 1.3rem;
			}
			h3 {
				font-size: 1.2rem;
			}
			h4 {
				font-size: 1.1rem;
			}
			h5 {
				font-size: 1rem;
			}
			h6 {
				font-size: 0.9rem;
			}
			p {
				font-size: 0.9rem;
			}
			span,
			a {
				font-size: 0.9rem;
			}
			input,
			textarea {
				font-size: 0.9rem;
			}

			@media screen and (min-width: ${({ theme }) => theme.screen_sizes.m}) {
				h1 {
					font-size: 3vw;
				}
				h2 {
					font-size: 2.8vw;
				}
				h3 {
					font-size: 2.6vw;
				}
				h4 {
					font-size: 2.3vw;
				}
				h5 {
					font-size: 2.2vw;
				}
				h6 {
					font-size: 2vw;
				}
				p {
					font-size: 1.6vw;
				}
				span,
				a {
					font-size: 1.6vw;
				}
				input,
				textarea {
					font-size: 1.6vw;
				}
			}

			@media screen and (min-width: ${({ theme }) => theme.screen_sizes.l}) {
				h1 {
					font-size: 2.2rem;
				}
				h2 {
					font-size: 2rem;
				}
				h3 {
					font-size: 1.8rem;
				}
				h4 {
					font-size: 1.6rem;
				}
				h5 {
					font-size: 1.4rem;
				}
				h6 {
					font-size: 0.9rem;
				}
				p {
					font-size: 0.7rem;
				}
				span,
				a {
					font-size: 0.7rem;
				}
				input,
				textarea {
					font-size: 0.7rem;
				}
			}
		}
	`
);
