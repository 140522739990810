import React from 'react';
import styled from "styled-components";
import { AppThemeColors } from 'Styles/Theme';


const DescriptionContainer = styled.p<IDescription>`
  color: ${({ theme, color = 'black' }) => theme.colors[color] };
  font-size: ${({ size = "0.9rem" }) => size };
  margin: ${({ margin = "0" }) => margin };
`;

interface IDescription {
  children: any;
  color?: AppThemeColors;
  size?: string;
  margin?: string;
}

const Description: React.FC<IDescription> = ({ children, color, size, margin }) => {
  return (
    <DescriptionContainer color={color} size={size} margin={margin} >
      {children}
    </DescriptionContainer>
  );
}

export default Description;