/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { flex } from 'Styles/tools';
import { ScheduleOptions } from 'Types';
import { HideHorizontalScrollBar } from 'Styles/mixins';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

interface ScheduleFiltersProps {
	handleFilterChange: (id: ScheduleOptions) => void;
	selected: string | number;
}

const FilterContainer = styled.div`
	${flex('row', 'space-evenly', 'center', 'nowrap')};
	overflow-x: scroll;
	flex-shrink: 0;
	flex: 0;
	margin: 2px 16px 10px 16px;
	border-radius: ${({ theme }): any => theme.rounded};
	background-color: ${({ theme, color = 'transparent' }): any => theme.colors[color]};
	${HideHorizontalScrollBar()}
`;

const FilterOption = styled.span<any>`
	background-color: ${({ theme, selected }): any => selected && theme.colors.black};
	color: ${({ theme, selected }): any => (selected ? theme.colors.white : theme.colors.black)};
	border-radius: ${({ theme, selected }): any => selected && theme.rounded};
	opacity: ${({ available }): any => (available ? '1' : '0.25')};
	padding: 10px 0;
	width: 100%;
	text-align: center;
	font-size: 0.8rem;
`;

const SCHEDULES_OPTIONS = [
	{ id: 'is_breakfast', label: 'schedules.is_breakfast' },
	{ id: 'is_lunch', label: 'schedules.is_lunch' },
	{ id: 'is_dinner', label: 'schedules.is_dinner' },
];

const ScheduleFilters: React.FC<ScheduleFiltersProps> = ({ selected, handleFilterChange }) => {
	// * REDUX STATE
	const dishes = useSelector((store: AppState) => store.dishes.data);

	// * LOCAL STATE
	const [shiftAvailability, setShiftAvailability] = useState<any>({
		is_breakfast_available: false,
		is_lunch_available: false,
		is_dinner_available: false,
	});

	// * LOCAL HELPERS
	const { t } = useTranslation();

	// * STATE MANAGEMENT
	useEffect(() => {
		if (!dishes?.length) return;

		const is_breakfast_available = dishes.some(({ is_breakfast }) => is_breakfast);
		const is_lunch_available = dishes.some(({ is_lunch }) => is_lunch);
		const is_dinner_available = dishes.some(({ is_dinner }) => is_dinner);

		setShiftAvailability({ is_breakfast_available, is_lunch_available, is_dinner_available });
	}, [dishes]);

	return (
		<FilterContainer className="schedule-filter-container">
			{SCHEDULES_OPTIONS.map(option => {
				const isOptionAvailable = shiftAvailability[`${option.id}_available`];
				const isCurrentOptSelected = selected === option.id;

				return (
					<FilterOption
						selected={isCurrentOptSelected}
						key={option.id}
						available={isOptionAvailable}
						onClick={(): void | null =>
							!isCurrentOptSelected && isOptionAvailable ? handleFilterChange(option.id as ScheduleOptions) : null
						}
					>
						{t(`${option.label}`)}
					</FilterOption>
				);
			})}
		</FilterContainer>
	);
};

export default ScheduleFilters;
