import React, { useState } from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

import { flex } from "Styles/tools";
import DishDetailPage from "./DishDetailPage";
import { ClientURL, Dish } from "Types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface DishDetailModalProps {
	close: () => void;
}

interface OverlayProps {
	showWine: boolean;
}

const Overlay = styled(motion.div)<OverlayProps>`
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	${flex("column", "flex-start", "flex-start", "nowrap")}
	display: ${({ showWine = false }) => (showWine ? "none" : "block")};
`;

const DishDetailModal: React.FC<DishDetailModalProps> = ({ close }: DishDetailModalProps) => {
	const { itemId, wineId } = useParams<ClientURL>();
	const dishes = useSelector((store: AppState) => store.dishes);
	const [showWine, setShowWine] = useState<boolean>(false);

	const dishSelected = findDishForDetails(itemId, dishes.data ?? []);

	return ReactDOM.createPortal(
		<>
			{itemId && (
				<Overlay
					className="overlay"
					initial={{ x: "125vw" }}
					animate={{ x: 0 }}
					transition={{ duration: 0.3 }}
					exit={{ x: "125vw" }}
					showWine={Boolean(showWine || wineId)}
				>
					<DishDetailPage setShowWine={setShowWine} close={close} dish={dishSelected} />
				</Overlay>
			)}
		</>,
		document.getElementById("portal") as Element
	);

	function findDishForDetails(dishId: string | undefined, dishes: Dish[] | null = []){
		if(dishId && dishes && dishes.length > 0)
			return dishes.find(dish => dish.id.toString() === dishId + "") ?? null;
		return null;
	};
};

export default DishDetailModal;
