import React, { useState } from "react";
import { URL_INTEGRAL } from "env";
import styled from "styled-components";
import table from "Assets/images/table/table.png";
import wood from "Assets/images/wood/wood.png";
import noImageBlackBottle from "Assets/images/bottle/bottle_default.png"

const WineBottleContainer = styled.div`
	position: relative;
`;

const WineBottleImageContainer = styled.div`
	position: absolute;
	top: 5vh;
	left: 50%;
	transform: translateX(-50%);
`;

const BackgroundImage = styled.img`
	height: 40vh;
	width: 100vw;
	@media screen and (min-width: ${({ theme }) => theme.screen_sizes.l}) {
		height: 55vh;
	}
`;

const FloorImage = styled.img`
	position: relative;
	top: -5px;
	height: 10vh;
	width: 100vw;
`;

const BottleImage = styled.img`
	height: 37vh;
	@media screen and (min-width: ${({ theme }) => theme.screen_sizes.l}) {
		height: 52vh;
	}
`;

interface WineBottle {
	clientId: string;
	wineImageName: string;
}

const WineBottle: React.FC<WineBottle> = ({ clientId, wineImageName }) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const imgObject = new Image();
	imgObject.onload = function(){
		setImageLoaded(true);
	}

	const image_url = `${URL_INTEGRAL}/wines/image/${clientId}/${wineImageName}`;

	imgObject.src = image_url;
	return (
		<WineBottleContainer>
			<BackgroundImage src={wood} alt="backgroundImage" />
			<FloorImage src={table} alt="floorImage" />
			<WineBottleImageContainer>
				<BottleImage src={imageLoaded ? image_url : noImageBlackBottle} alt="bottleImage" />
			</WineBottleImageContainer>
		</WineBottleContainer>
	);
};

export default WineBottle;