import React from "react";
import styled from "styled-components";
import LogoSrc from "Assets/logo-black@2x.png";
import LogoWhiteSrc from "Assets/logo-white@2x.png";
import { flex } from "Styles/tools";

interface LogoContainerProps {
	width?: string;
	height?: string;
	padding?: string;
	white?: boolean;
}

const LogoContainer = styled.div<LogoContainerProps>`
	width: ${({ width = "100%" }): any => width};
	padding: ${({ padding = "1em 0" }): any => padding};
	${flex("column", "center", "center", "nowrap")}
`;

interface LogoProps extends LogoContainerProps {
	size?: string;
}
const Logo: React.FC<LogoProps> = ({ width, height, size, padding, white }) => {
	return (
		<LogoContainer width={width} height={height} padding={padding}>
			<img
				src={white ? LogoWhiteSrc : LogoSrc}
				style={{ width: size ? size : "150px", height: "auto", margin: "0 auto" }}
				alt=""
			/>
		</LogoContainer>
	);
};

export default Logo;
