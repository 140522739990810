import React, { useEffect } from "react";

import { ClientURL, Dish } from "Types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Wine } from "Types";
import WineDetailModal from "Pages/RestaurantView/RestaurantCellar/WineDetailModal";
import PageContent from "Components/Shared/Page/PageContent";
import { Description } from "Components/Core";
import { Page, PageHeader, Title, FlexContainer, ListContainer, ListItem, Image } from "Components/Shared";

import DEFAULT_IMAGE_FOOD from "Assets/icons/food.png";
import styled from "styled-components";
import { flex } from "Styles/tools";
import { formatCurrencyAmount } from "Utils";
import { useHistory, useLocation, useParams } from "react-router-dom";

interface DishDetailPageProps {
	setShowWine: (showWine: boolean) => void;
	close: () => void;
	dish: Dish | null;
}

const DescriptionBox = styled.div`
	${flex("row", "space-between", "baseline", "nowrap")}
	padding: 10px 0;
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.colors.transparent};
`;
const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;

const Label = styled.span``;

const DishDetailPage: React.FC<DishDetailPageProps> = ({ setShowWine, dish, close }: DishDetailPageProps) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { clientId, restaurantId, sectionId, itemId, wineId } = useParams<ClientURL>();
	const { search } = useLocation(); // search contains optional query params

	const wines = useSelector((store: AppState) => store.wines.data);
	const client = useSelector((state: AppState) => state.client.data);

	const [currentLang] = i18n.language.split("-");
	const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;


	const openWineDetail = (wine: Wine) => {
		setShowWine(true);
		if (client?.client_type === "hotel") {
			history.push(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}/${itemId}/${wine.id}${search}`);
		} else {
			history.push(`/restaurant/${clientId}/${sectionId}/${itemId}/${wine.id}${search}`);
		}
	};

	const closeWineDetail = () => {
		setShowWine(false);
		if (client?.client_type === "hotel") {
			history.replace(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}/${itemId}`);
		} else {
			history.replace(`/restaurant/${clientId}/${sectionId}/${itemId}`);
		}
	};

	useEffect(() => {
		setShowWine(wineId ? true : false);
	}, [setShowWine, wineId]);

	return (
		<>
			<Page>
				<PageHeader
					color="black"
					textColor="white"
					backEnabled={true}
					backText={t("common.return")}
					customBackAction={close}
				/>
				<PageContent className="page-content">
					{/* Dish detail description */}
					<FlexContainer
						width="100%"
						padding={dish?.dish_img ? "0 1em" : "2em 1em"}
						direction="column"
						justify="center"
						align="center">
						{/* Image */}
						{dish?.dish_img ? (
							<Image id={dish.id} srcImg={dish.dish_img} width="45vw" height="28vw" />
						) : (
							<Image id={"def"} srcImg={DEFAULT_IMAGE_FOOD} height="15vh" width="15vw" />
						)}
						{/* Name */}
						<Title className="bold" color="black" margin="0.5em auto">
							{dish ? dish[`dish_name_${currentLang}` as keyof typeof dish] || t("common.noInfoAvailableInThisLanguage") : "--"}
						</Title>
						{/* Detail description */}
						<Description color="gray" margin="1rem 0 2rem 0">
							{dish ? dish[`dish_desc_${currentLang}` as keyof typeof dish] || t("common.noInfoAvailableInThisLanguage") : "--"}
						</Description>
						{/* Price */}
						{dish?.dish_price !== null && dish?.dish_price !== 0 && (
							<DescriptionBox className="description-box-price">
								<Label>{t(`drinkDetail.price`)}</Label>
								<Label className="bold">{`${dish ? formatCurrencyAmount(dish.dish_price, client?.main_currency, decimals) : "0.00"}`}
								</Label>
							</DescriptionBox>
						)}
					</FlexContainer>

					{/* Dish pairings */}
					<FlexContainer width="100%" padding="1rem 0" direction="column" justify="center" align="center">
						<Title className="bold" color="black" margin="0.5em auto">
							{t("menuView.pairings")}
						</Title>
						{/* Loader for fetching data */}
						{!wines && <Description>{`${t("common.loading")}...`}</Description>}
						{/* Pairings list result or without pairings message */}
						{dish?.wines.length ? (
							<ListContainer className="restaurant-list" height="55vh">
								{dish.wines.map((pairedWine: number, index: number) => {
									const matchedWine: Wine | undefined = wines?.find(wine => wine.id === pairedWine && !wine.outOfStock);
									return (
										matchedWine && (
											<ListItem onClick={() => openWineDetail(matchedWine)} key={index}>
												{/* Wine name is now hardcoded to ES due every label has same name on differente languages. This prevent fails on new Portuguese language */}
												<h5>{matchedWine[`nameES` as keyof typeof matchedWine]}</h5>
											</ListItem>
										)
									);
								})}
							</ListContainer>
						) : (
							<Description>{t("menuView.withoutPairings")}</Description>
						)}
					</FlexContainer>
				</PageContent>
			</Page>

			{/* Auxiliar wine detail component to show paired wine data */}
			<WineDetailModal close={closeWineDetail} />
		</>
	);
};

export default DishDetailPage;
