import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { AppThemeColors } from '../../Styles/Theme';

type BadgeContainerType = {
	color?: AppThemeColors;
	textColor?: AppThemeColors;
	margin?: string;
};

const BadgeContainer = styled.div<BadgeContainerType>`
	background-color: ${({ theme, color = 'black' }) => theme.colors[color]};
	color: ${({ theme, textColor = 'white' }) => theme.colors[textColor]};
	margin: ${({ margin = '' }) => margin};
	padding: 7px 16px;
	border-radius: ${({ theme }) => theme.rounded};
	text-transform: capitalize;
`;

interface BadgeProps {
	color?: AppThemeColors;
	textColor?: AppThemeColors;
	margin?: string;
	children: ReactNode;
	onClick?: () => void;
	id?: string;
}

// eslint-disable-next-line react/display-name
const Badge = React.forwardRef<HTMLInputElement, BadgeProps>((props, ref) => {
	const { color, textColor, children, margin, id, onClick } = props;
	return (
		<BadgeContainer id={id} color={color} textColor={textColor} margin={margin} onClick={onClick} ref={ref}>
			{children}
		</BadgeContainer>
	);
});

export default Badge;
