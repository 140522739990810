/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Badge } from "Components/Shared";
import { flex } from "Styles/tools";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { HideHorizontalScrollBar } from "Styles/mixins";
import { scrollTo } from "Utils/behaviour";
import { getGlobalLanguage } from "Utils/language.helper";

const FilterContainer = styled.div`
	position: relative;
	flex-shrink: 0;
	padding: 0 16px;
`;

const SelectedFiltersContainer = styled.div`
	overflow-x: scroll;
	width: 100%;
	flex-shrink: 0;
	height: 100%;
	flex: 0;
	${flex("row", "flex-start", "center", "nowrap")};
	${HideHorizontalScrollBar()}
`;

interface MenuFiltersProps {
	selected: string | number;
	categoriesRefs: any;
	options: any;
}

interface Badge {
	_id: number;
	name_en: string;
	name_es: string;
	name_pt?: string;
	order: number;
	superId?: number;
}

const HORIZONTAL_SCROLL_DEBOUNCE = 1_000; // ms

const MenuFilters: React.FC<MenuFiltersProps> = ({ selected, categoriesRefs, options }) => {
	// * LOCAL HELPERS
	const { i18n, t } = useTranslation();
	const currentLang = getGlobalLanguage(i18n.language).toUpperCase();
	// * REDUX STATE
	const showGlobalSelfCategories = useSelector(
		(store: AppState) => store.client.data?.client_settings?.show_global_selfcategories
	);
	// * LOCAL STATE
	const badgesRef = useRef<any>([]);

	// * LOCAL FUNCTIONS
	const badges: Badge[] = options.flat().map((option: any) => {
		if (showGlobalSelfCategories) {
			return {
				...option.category,
				name_en: option.category.name_en || t("common.noInfoAvailableInThisLanguage"),
				name_es: option.category.name_es || t("common.noInfoAvailableInThisLanguage"),
				name_pt: option.category.name_pt || t("common.noInfoAvailableInThisLanguage"),
				_id: option.category.Id,
			};
		}
		return {
			...option.selfcategory,
			name_en: option.selfcategory.nameEN || t("common.noInfoAvailableInThisLanguage"),
			name_es: option.selfcategory.nameES || t("common.noInfoAvailableInThisLanguage"),
			name_pt: option.selfcategory.namePT || t("common.noInfoAvailableInThisLanguage"),
			_id: option.selfcategory.id,
		};
	});

	const handleClick = (idx: number): void => {
		scrollTo(categoriesRefs.current[idx]);
	};

	// * STATE MANAGEMENT
	useEffect(() => {
		if (!badgesRef || !selected) return;

		const badgesRefLen = badgesRef.current.length;
		for (let idx = 0; idx < badgesRefLen; idx++) {
			const categoryRef = badgesRef.current[idx];
			if (!categoryRef) continue; // Validate if category ref is still existing to prevent app crash (when changing schedule shift)
			if (Number(categoryRef.id) !== selected) continue;
			setTimeout(() => scrollTo(categoryRef), HORIZONTAL_SCROLL_DEBOUNCE); // set timeout as debounce to scroll selected badge at start (left)
		}
	}, [selected]);

	return (
		<>
			<FilterContainer className="filter-container">
				<SelectedFiltersContainer className="selected-filters-container">
					{badges && badges.length
						? badges.map((category: Badge, categoryIdx: number) => (
								<Badge
									margin="0 1rem 0 0"
									color={selected === category._id ? "black" : "transparent"}
									textColor={selected === category._id ? "white" : "black"}
									onClick={(): void | null => (selected !== category._id ? handleClick(categoryIdx) : null)}
									key={category._id}
									id={`${category._id}`}
									ref={(node: any): void => (badgesRef.current[categoryIdx] = node)}>
									<span style={{ whiteSpace: "pre" }}>
										{category[`name_${currentLang.toLocaleLowerCase()}` as keyof typeof category]}
									</span>
								</Badge>
						  ))
						: null}
				</SelectedFiltersContainer>
			</FilterContainer>
		</>
	);
};

export default MenuFilters;
