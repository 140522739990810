import React, { ReactNode } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { AppTheme } from "Styles/Theme";
import { flex } from "Styles/tools";
import { ActionBarType, ACTION_BAR_TYPES } from "Types";

const ACTION_EVENT_NAME = {
	[ACTION_BAR_TYPES.RESTAURANT]: "restaurant",
	[ACTION_BAR_TYPES.CELLAR]: "cellar",
	[ACTION_BAR_TYPES.MENU]: "menu",
	[ACTION_BAR_TYPES.DRINKS]: "drinks",
};

const ActionBarItemContainer = styled.div`
	${flex("column", "center", "center", "nowrap")};
	flex: 1;
`;

const ActionBarItemLabel = styled(motion.span)``;

interface ActionBarItemProps {
	id: ActionBarType;
	label: string;
	children: ReactNode;
	active: boolean;
	disabled: boolean;
	onClick: (id: ActionBarType) => void;
}

const ActionBarItem: React.FC<ActionBarItemProps> = ({
	label,
	children,
	active,
	disabled = false,
	id,
	onClick,
}: ActionBarItemProps) => {
	return (
		<ActionBarItemContainer
			className={`action-bar-item-container umami--click--${ACTION_EVENT_NAME[id]}`}
			onClick={(): boolean | void => !disabled && onClick(id)}>
			{children}
			<ActionBarItemLabel
				className="action-bar-item-label"
				animate={{ color: disabled ? AppTheme.colors.gray : active ? AppTheme.colors.red : AppTheme.colors.black }}>
				{label}
			</ActionBarItemLabel>
		</ActionBarItemContainer>
	);
};

export default ActionBarItem;
