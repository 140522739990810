/* eslint-disable @typescript-eslint/camelcase */
import { Dispatch, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store";
import { getRestaurantCellar } from "../Reducers/Wines";
import { useTranslation } from "react-i18next";
import { Wine, WineCategoriesType } from "../Types";
import { setWineCategoriesActionCreator } from "../Reducers/Categories";

export const rebuildDynamicFilters = (labels: Wine[], wine: WineCategoriesType | null, dispatch: Dispatch<any>): void => {
	const categoriesWine: WineCategoriesType = {
		countryEN: [],
		countryES: [],
		countryPT: [],
		regionEN: [],
		regionES: [],
		regionPT: [],
		subregionEN: [],
		subregionES: [],
		subregionPT: [],
		typeEN: [],
		typeES: [],
		typePT: [],
		varietalEN: [],
		varietalES: [],
		varietalPT: [],
	};

	const addIfNotExists = (element: string, array: string[]): void => {
		!array.includes(element) && array.push(element);
	};

	labels.forEach((wine: Wine) => {
		addIfNotExists(wine.country, categoriesWine.countryES);
		addIfNotExists(wine.countryEN, categoriesWine.countryEN);
		addIfNotExists(wine.countryPT, categoriesWine.countryPT);
		addIfNotExists(wine.region, categoriesWine.regionES);
		addIfNotExists(wine.regionEN, categoriesWine.regionEN);
		addIfNotExists(wine.regionPT, categoriesWine.regionPT);
		addIfNotExists(wine.subregion, categoriesWine.subregionES);
		addIfNotExists(wine.subregionEN, categoriesWine.subregionEN);
		addIfNotExists(wine.subregionPT, categoriesWine.subregionPT);
		addIfNotExists(wine.typeES, categoriesWine.typeES);
		addIfNotExists(wine.typeEN, categoriesWine.typeEN);
		addIfNotExists(wine.typePT, categoriesWine.typePT);
		addIfNotExists(wine.varietalES, categoriesWine.varietalES);
		addIfNotExists(wine.varietalEN, categoriesWine.varietalEN);
		addIfNotExists(wine.varietalPT, categoriesWine.varietalPT);
	});

	dispatch(setWineCategoriesActionCreator({ ...wine, ...categoriesWine }));
};

export default function useFetchWines(clientid: string, winesFilter: any, limit = 20): any {

	const wines = useSelector((store: AppState) => store.wines);
	const {page, status} = useSelector((state: AppState) => state.wines);
	const client = useSelector((state: AppState) => state.client);

	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	const [lang] = i18n.language.split("-");
	const fullCellarFilters = {
		...winesFilter,
		wine_order: client.data?.wine_order,
		wine_sort: client.data?.wine_sort,
	};

	useEffect(() => {
		const getWines = async (): Promise<any> => {
			if (!clientid) return;

			try {
				// * Actually fetching wines
				dispatch(getRestaurantCellar({
					clientId: clientid,
					page,
					filters: fullCellarFilters,
					language: lang.toUpperCase(),
					winesFilter,
				}))
			} catch (e) {
				console.log(e);
			}
		};
		getWines();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, clientid, winesFilter, dispatch, i18n.language, limit, client.data]);

	return { wineError: status === "Failed", wines, fetchingWines: status === "Loading", hasMoreWines: false };
}
