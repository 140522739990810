import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CellarIcon, DrinksIcon, MenuIcon, PlaceIcon } from "../icons";

import { flex } from "Styles/tools";
import ActionBarItem from "./ActionBarItem";
import { useTranslation } from "react-i18next";
import { ActionBarType, ACTION_BAR_TYPES } from "Types";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface ActionBarProps {
	active: ActionBarType;
	menu?: boolean;
	restaurantDetails?: boolean;
	onChange: (id: ActionBarType) => void;
}

const ActionBarContainer = styled.div`
	${flex("row", "space-evenly", "center", "nowrap")}
	background-color: ${({ theme }) => theme.colors.light_gray};
	border-top: ${({ theme }) => `2px solid ${theme.colors.transparent}`};
	width: 100%;
	height: 10vh;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 2rem 0;
`;

const ActionBar: React.FC<ActionBarProps> = ({ active, menu, restaurantDetails, onChange }: ActionBarProps) => {
	const { t, i18n } = useTranslation();
	const pairings = useSelector((store: AppState) => store.client.data?.pairings);

	const [actionBarOptions, setActionBarOptions] = useState([
		{ id: ACTION_BAR_TYPES.RESTAURANT, label: t("common.restaurant") },
		{ id: ACTION_BAR_TYPES.CELLAR, label: t("common.cellar") },
		{ id: ACTION_BAR_TYPES.MENU, label: t("common.menu") },
		{ id: ACTION_BAR_TYPES.DRINKS, label: t("common.drinks") },
	]);

	const getIcon = (id: ActionBarType) => {
		switch (id) {
			case ACTION_BAR_TYPES.RESTAURANT:
				return (
					<PlaceIcon color={!restaurantDetails ? "gray" : active === ACTION_BAR_TYPES.RESTAURANT ? "red" : "black"} />
				);
			case ACTION_BAR_TYPES.CELLAR:
				return <CellarIcon color={active === ACTION_BAR_TYPES.CELLAR ? "red" : "black"} />;
			case ACTION_BAR_TYPES.MENU:
				return <MenuIcon color={!pairings || !menu ? "gray" : active === ACTION_BAR_TYPES.MENU ? "red" : "black"} />;
			case ACTION_BAR_TYPES.DRINKS:
				return (
					<DrinksIcon color={!pairings || !menu ? "gray" : active === ACTION_BAR_TYPES.DRINKS ? "red" : "black"} />
				);
			default:
				return <></>;
		}
	};

	// This hook allow reload labels when automatically changes client language (i18n.language)
	useEffect(() => {
		setActionBarOptions([
			{ id: ACTION_BAR_TYPES.RESTAURANT, label: t("common.restaurant") },
			{ id: ACTION_BAR_TYPES.CELLAR, label: t("common.cellar") },
			{ id: ACTION_BAR_TYPES.MENU, label: t("common.menu") },
			{ id: ACTION_BAR_TYPES.DRINKS, label: t("common.drinks") },
		]);
	}, [i18n, t]);

	return (
		<ActionBarContainer className="action-bar-container">
			{actionBarOptions.map(option => (
				<ActionBarItem
					key={option.id}
					id={option.id as ActionBarType}
					label={t(`${option.label}`)}
					active={active === option.id}
					disabled={
						([ACTION_BAR_TYPES.MENU, ACTION_BAR_TYPES.DRINKS].includes(option.id) ? !pairings || !menu : false) ||
						(option.id === ACTION_BAR_TYPES.RESTAURANT && !restaurantDetails)
					}
					onClick={onChange}
				>
					{getIcon(option.id as ActionBarType)}
				</ActionBarItem>
			))}
		</ActionBarContainer>
	);
};

export default ActionBar;
