/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";

import { AppTheme, AppThemeColors } from "../../../Styles/Theme";
import { LanguageSelect } from "../../Core";
import { flex } from "../../../Styles/tools";
import Title from "../Title";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ClientURL } from "Types";
import WALogo from "Assets/icon.png";
import About from "./About";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isUsingCellarFilters } from "Utils";

const PageHeaderContainer = styled(motion.div)`
	width: 100%;
	height: fit-content;
	padding: 16px;
	flex-grow: 0;
`;

const HeaderNavBar = styled.div`
	${flex("row", "space-between", "center", "nowrap")};
`;

const BackContainer = styled.div`
	${flex("row", "flex-start", "center", "nowrap")};
	flex: 1;
`;

const HeaderBackText = styled.h3`
	pointer-events: none;
`;

const TitleContainer = styled.div`
	${flex("row", "center", "center", "nowrap")};
	flex: 3;
`;

const SelectContainer = styled.div`
	${flex("row", "flex-end", "center", "nowrap")};
	flex: 1;
`;

const HeaderContent = styled.div`
	margin-top: 15px;
`;

const LogoContainer = styled.span`
	margin-right: 1rem;
`;

interface PageHeaderProps {
	color: AppThemeColors;
	textColor: AppThemeColors;
	backEnabled?: boolean;
	backText?: string;
	title?: string;
	children?: ReactNode;
	customBackAction?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
	title,
	backEnabled = false,
	backText,
	color,
	textColor,
	customBackAction,
	children,
}: PageHeaderProps) => {
	const history = useHistory();
	const { clientId } = useParams<ClientURL>();
	const { search } = useLocation(); // search contains optional query params
	const [showFiltersModal, setshowFiltersModal] = useState(false);
	const filters = useSelector((state: AppState) => state.filters.cellar);
	const isUsingFilters = isUsingCellarFilters(filters);

	const openModal = () => setshowFiltersModal(true);
	const closeModal = () => setshowFiltersModal(false);

	const returnPage = () => {
		history.push(`/hotel/${clientId}${search}`);
	};

	return (
		<PageHeaderContainer className="page-header-container" animate={{ backgroundColor: AppTheme.colors[color] }}>
			<HeaderNavBar className="header-nav">
				<LogoContainer onClick={openModal}>
					<img alt="banner-app-logo" style={{ width: "2rem", height: "auto" }} src={WALogo} />
				</LogoContainer>
				<BackContainer className="back-container" onClick={customBackAction ? customBackAction : returnPage}>
					<AnimatePresence>
						{backEnabled && (
							<motion.div
								id="icon"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								style={{ display: "flex", alignItems: "center" }}
								key="page-header">
								<IoIosArrowBack
									className="back-icon"
									style={{ color: AppTheme.colors[textColor], fontSize: "1.4em", marginLeft: "-9%", marginTop: "0" }}
								/>
							</motion.div>
						)}
						{backText && (
							<motion.div id="text" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
								<HeaderBackText className="header-back-text" style={{ color: AppTheme.colors[textColor] }}>
									{backText}
								</HeaderBackText>
							</motion.div>
						)}
					</AnimatePresence>
				</BackContainer>

				{title && (
					<TitleContainer className="title-container">
						<Title className="header-title">{title}</Title>
					</TitleContainer>
				)}

				{!isUsingFilters && (
					<SelectContainer className="select-container">
						<LanguageSelect textColor={textColor} />
					</SelectContainer>
				)}
			</HeaderNavBar>
			{children && <HeaderContent className="header-content">{children}</HeaderContent>}
			<About isOpen={showFiltersModal} close={closeModal} />
		</PageHeaderContainer>
	);
};

export default PageHeader;
