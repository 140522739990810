import { ICellarFilters } from "Reducers/Filters";
import formatCurrencyAmount from "./formatCurrencyAmount";
import { filterDrinksByRestaurant, filterDishesByRestaurant } from "./hotelFilter";
import { getFlagCode } from "./flags"

const isUsingCellarFilters = (filters: ICellarFilters): boolean => {
	const isUsingFilters =
		filters.type ||
		filters.varietal ||
		filters.country ||
		filters.region ||
		filters.subregion ||
		filters.selling_by ||
		filters.selection;
	return Boolean(isUsingFilters);
};

export { formatCurrencyAmount, filterDrinksByRestaurant, filterDishesByRestaurant, isUsingCellarFilters, getFlagCode };
