import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

type ButtonType = { margin?: string };
const Button = styled(motion.button)<ButtonType>`
	padding: 0.5em 1em;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.red};
	border: 0;
	border-radius: ${({ theme }) => theme.rounded};
	margin: ${({ margin = '10px' }) => margin};
	outline: 0;
`;

interface ButtonProps {
	children: ReactNode;
	margin?: string;
	onClick: () => void;
	bold?: boolean;
}

const index: React.FC<ButtonProps> = ({ children, margin, bold, onClick }: ButtonProps) => {
	return (
		<Button whileTap={{ scale: 0.95 }} onClick={onClick} margin={margin} className={bold ? 'bold' : ''}>
			{children}
		</Button>
	);
};

export default index;
