import React from 'react';
import styled from "styled-components";


const ImageContainer = styled.img`
  height: ${({ height = 'auto' }) => height };
  width: ${({ width = '100vw' }) => width };
`;

interface IImageProps {
  id: string;
  srcImg: string;
  height?: string;
  width?: string;
}

const Image: React.FC<IImageProps>  = ({ id, srcImg, height, width }) => {
  return (
    <ImageContainer src={`${srcImg}`} alt={`image_${id}`} height={height} width={width} />
  );
};

export default Image;
