import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

import { flex } from "Styles/tools";
import DrinkDetailPage from "./DrinkDetailPage";
import { ClientURL, Drink } from "Types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface DrinkDetailModalProps {
	close: () => void;
}

const Overlay = styled(motion.div)`
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	${flex("column", "flex-start", "flex-start", "nowrap")}
`;

const DrinkDetailModal: React.FC<DrinkDetailModalProps> = ({ close }: DrinkDetailModalProps) => {
	const { itemId } = useParams<ClientURL>();
	const drinks = useSelector( (store: AppState) => store.drinks.data);

	const drinkSelected = findDrinkForDetails(itemId, drinks ?? []);

	return ReactDOM.createPortal(
		<>
			{itemId && (
				<Overlay
					className="overlay"
					initial={{ x: "125vw" }}
					animate={{ x: 0 }}
					transition={{ duration: 0.3 }}
					exit={{ x: "125vw" }}
				>
					<DrinkDetailPage close={close} drink={drinkSelected} />
				</Overlay>
			)}
		</>,
		document.getElementById("portal") as Element
	);

	function findDrinkForDetails(itemId: string | undefined, drinks: Drink[]){
		if(itemId && drinks && drinks.length > 0)
			return drinks.find(drink => drink._id.toString() === itemId + "") ?? null;
		return null;
	}
};

export default DrinkDetailModal;
