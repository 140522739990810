import React from 'react';
import styled from 'styled-components';

import { Drink } from 'Types';
import { flex } from 'Styles/tools';
import { useTranslation } from 'react-i18next';
import { FlexContainer } from 'Components/Shared';
import { formatCurrencyAmount } from 'Utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';


const DrinkListItemContainer = styled.div`
  ${ flex('column', 'flex-start', 'baseline', 'nowrap')}
  width: 100%;
`;

const DrinkName = styled.h4`
  color: ${ ({theme}) => theme.colors.black};
`;

const DrinkPrice = styled.h5`
  color: ${ ({theme}) => theme.colors.blue};
`;

const DrinkDescription = styled.h5`
  color: ${ ({theme}) => theme.colors.gray};
  font-size: 0.8em;
`;

const DrinkVolume = styled.h5`
  color: ${ ({theme}) => theme.colors.light_black};
  font-size: 0.9em;
`;

const DEFAULT_CURRENCY_DIGITS = 2;
const NO_CURRENCY_DIGITS = 0;

interface IDrinkListItem {
  drink: Drink;
}
const DrinkListItem: React.FC<IDrinkListItem> = ({drink}: IDrinkListItem) => {
  const { t, i18n } = useTranslation();
  const client = useSelector((state: AppState) => state.client.data);
  const currentLang = i18n.language.split('-')[0].toUpperCase(); 
  const decimals = client?.client_settings.decimals === undefined? DEFAULT_CURRENCY_DIGITS: client?.client_settings.decimals? DEFAULT_CURRENCY_DIGITS : NO_CURRENCY_DIGITS;
 

  return (
    <DrinkListItemContainer className='drink-list-item-container'>
      {/* Title */}
      <DrinkName className='drink-name'>{drink[`name${currentLang}` as keyof typeof drink] || t("common.noInfoAvailableInThisLanguage")}</DrinkName>
      {/* Description */}
      {drink[`desc${currentLang}` as keyof typeof drink] && (
        <FlexContainer width='100%' margin='0.5em 0 0 0' className='drink-description-row'>
          <FlexContainer className='drink-description'>
            <DrinkDescription>{`${drink[`desc${currentLang}` as keyof typeof drink]}`}</DrinkDescription>
          </FlexContainer>
        </FlexContainer>
      )}
      {/* Volume */}
      {Number(drink.volume) !== 0 && (
        <FlexContainer width='100%' margin='0.5em 0' className='drink-description-row'>
          <FlexContainer className='drink-description'>
            <DrinkVolume>{`${drink.volume} ${drink.unit}`}</DrinkVolume>
          </FlexContainer>
        </FlexContainer>
      )}
      {/* Price */}
      <FlexContainer width='100%' margin='0.5em 0' className='drink-price-row'>
        <FlexContainer margin='0 0.6em 0 0' className='drink-price'>
          {drink.price !== null && drink.price !== 0 && (
            <DrinkPrice>
              {`${formatCurrencyAmount(drink.price, client?.main_currency, decimals)}`}
            </DrinkPrice>
          )}
        </FlexContainer>
      </FlexContainer>
    </DrinkListItemContainer>
  )
}

export default DrinkListItem;
