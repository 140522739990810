import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import HotelView from "./Pages/HotelView";
import RestaurantView from "./Pages/RestaurantView";
import WelcomeView from "./Pages/WelcomeView";
import ErrorView from "./Pages/ErrorView";
import { AnimatePresence } from "framer-motion";
import { AppDownloadBanner, CookiesAdvertising } from "./Components/Shared";

const OPEN_BANNER_TIMEOUT_SEC = 10_000;

const App = (): any => {
	const [isBannerOpen, setIsBannerOpen] = useState(true);

	useEffect(() => {
		if (isBannerOpen) return;

		const timer = setTimeout(() => setIsBannerOpen(true), OPEN_BANNER_TIMEOUT_SEC);

		return (): void => {
			clearTimeout(timer);
		};
	}, [isBannerOpen]);

	return (
		<Router>
			<AnimatePresence>
				<AppDownloadBanner key="download-banner" isOpen={isBannerOpen} handleBanner={setIsBannerOpen} />
				<Switch>
					<Route
						exact
						path="/client-app/:clientType?/:clientId/:sectionId?/:itemId?/:wineId?"
						render={({ location }): any => {
							const [, username] = location.pathname.split("/client-app/");
							return <Redirect to={{ ...location, pathname: `/${username}`, search: location.search }} />;
						}}
					/>
					<Route exact path="/:clientId" render={(props): any => <WelcomeView {...props} />} />
					<Route
						path="/private-event/:clientId/:sectionId?/:itemId?/:wineId?"
						render={(props): any => <RestaurantView isBannerOpen={isBannerOpen} {...props} />}
					/>
					<Route
						path="/restaurant/:clientId/:sectionId?/:itemId?/:wineId?"
						render={(props): any => <RestaurantView isBannerOpen={isBannerOpen} {...props} />}
					/>
					<Route
						exact
						path="/hotel/:clientId"
						render={(props): any => <HotelView isBannerOpen={isBannerOpen} {...props} />}
					/>
					<Route
						path="/hotel/:clientId/restaurant/:restaurantId/:sectionId?/:itemId?/:wineId?"
						render={(props): any => <RestaurantView isBannerOpen={isBannerOpen} {...props} />}
					/>
					<Route path="*" render={(props): any => <ErrorView {...props} />} />
				</Switch>
				<CookiesAdvertising key="cookies-banner" />
			</AnimatePresence>
		</Router>
	);
};

export default App;
