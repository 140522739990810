const PROD_URL_API = "https://api.wineadvisor.mx:8002/api";
const PROD_URL_INTEGRAL = "https://api.wineadvisor.mx:7999/api";
const DEV_URL_API = "https://develop.wineadvisor.mx:8002/api";
const DEV_URL_INTEGRAL = "https://develop.wineadvisor.mx:81/api";

const APP_VERSION = "1.14.5";
const PRODUCTION = process.env.REACT_APP_NODE_ENVIRONMENT === "production";
const URL_API = PRODUCTION ? PROD_URL_API : DEV_URL_API;
const URL_INTEGRAL = PRODUCTION ? PROD_URL_INTEGRAL : DEV_URL_INTEGRAL;
const AUTHORIZATION = "4e4810334f645fd490ae05401303328b5da01a20";
const PUBLIC_TOKEN = "53669d6f00a32539945e3a845bd0bf08363a6a6c";

export { APP_VERSION, PRODUCTION, URL_API, URL_INTEGRAL, AUTHORIZATION, PUBLIC_TOKEN };
