import React, { ReactElement, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { useSelector } from "react-redux";
import { Page, PageHeader, ActionBar, Loading } from "Components/Shared";
import PageContent from "Components/Shared/Page/PageContent";
import { ACTION_BAR_TYPES, ActionBarType, ClientType, ClientURL } from "Types";
import { AppState } from "store";
import RestaurantDetail from "./RestaurantDetail";
import RestaurantCellar from "./RestaurantCellar";
import { useTranslation } from "react-i18next";
import RestaurantMenu from "./RestaurantMenu";
import { useFetchClientData, useFetchDishes, useFetchDrinks, useFetchWines, useProcessQueryParams } from "Hooks";
import RestaurantDrinks from "./RestaurantDrinks";

interface RestaurantViewProps {
	isBannerOpen: boolean;
}
const MAX_LIMIT_WINES = 5000;
const RestaurantView: React.FC<RestaurantViewProps> = ({ isBannerOpen }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { isLoading, data: clientData } = useSelector((state: AppState) => state.client);
	const { search } = useLocation(); // search contains optional query params
	
	const { clientId, restaurantId, sectionId } = useParams<ClientURL>();
	const queryParams = new URLSearchParams(search); // get query params on original URL
	const clientToSearch = queryParams.get("clientid") || clientId; // set clientid (queryparam) or clientId to dispatch search
	const { sourceURL, isMenuAvailable, isRestaurantDetailsAvailable, homePage, goBackOriginPage } = useProcessQueryParams();
	const [tab, setTab] = useState(homePage);
	const { fetchData } = useFetchClientData(clientToSearch, restaurantId);
	
	const client = useSelector((store: AppState) => store.client);
	const filters = useSelector((state: AppState) => state.filters.cellar);
	const { fetchDishGlobalCategories, fetchSelfDishCategories } = useFetchDishes(
		client.data ? client.data.id : "",
		1,
		filters,
		restaurantId
	);
	const { fetchDrinkGlobalCategories, fetchSelfDrinkCategories } = useFetchDrinks(
		client.data ? client.data.name : null,
		1,
		filters,
		restaurantId
	);
	useEffect(() => {
		fetchDishGlobalCategories();
	}, [fetchDishGlobalCategories]);

	useEffect(() => {
		fetchSelfDishCategories();
	}, [fetchSelfDishCategories]);

	useEffect(() => {
		fetchDrinkGlobalCategories();
	}, [fetchDrinkGlobalCategories]);

	useEffect(() => {
		fetchSelfDrinkCategories();
	}, [fetchSelfDrinkCategories]);

	useFetchWines(clientId, filters, MAX_LIMIT_WINES);

	const onTabChange = (id: ActionBarType): void => {
		if (clientData?.client_type === "hotel") {
			history.push(`/hotel/${clientId}/restaurant/${restaurantId}/${id}${search}`);
		} else {
			history.push(`/restaurant/${clientId}/${id}${search}`);
		}

		// Umami tracker utility to manually dispatch register app action bar events (clicks)
		(window as any).umami.trackEvent(`${id}`, `click`);

		// Set new selected tab option id
		setTab(id);
	};

	const getComponentToRender = (): ReactElement => {
		const currentView = sectionId ?? homePage;

		switch (currentView) {
			case ACTION_BAR_TYPES.CELLAR:
				return <RestaurantCellar />;
			case ACTION_BAR_TYPES.MENU:
				return <RestaurantMenu />;
			case ACTION_BAR_TYPES.DRINKS:
				return <RestaurantDrinks />;
			default:
				return <RestaurantDetail />;
		}
	};

	useEffect(() => {
		setTab(sectionId || homePage);
	}, [sectionId, homePage]);

	useEffect(() => {
		clientToSearch && fetchData(); // if a client (mongoId or queryparam) is in URL, then fetchData is dispatched
	}, [clientToSearch, fetchData]);

	// Fallback if user reload app

	return (
		<Page customHeight={isBannerOpen ? 92 : 100}>
			<PageHeader
				color="black"
				textColor="white"
				backText={
					clientData?.client_type === ClientType.HOTEL ? t("common.restaurants") : sourceURL ? t("common.goBack") : ""
				}
				backEnabled={clientData?.client_type === ClientType.HOTEL || sourceURL ? true : false}
				customBackAction={sourceURL && !restaurantId ? goBackOriginPage(sourceURL) : undefined}
			/>
			<PageContent className="page-content">
				<AnimatePresence exitBeforeEnter>
					{isLoading ? <Loading show={true} logo={true} /> : getComponentToRender()}
				</AnimatePresence>
			</PageContent>
			<ActionBar
				onChange={onTabChange}
				active={tab as ActionBarType}
				menu={isMenuAvailable}
				restaurantDetails={isRestaurantDetailsAvailable}
			/>
		</Page>
	);
};

export default RestaurantView;
