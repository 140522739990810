import React, { useEffect } from 'react';
import { ListContainer, ListItem } from '../../Components/Shared';
import { useDispatch } from 'react-redux';
import { resetFiltersActionCreator } from '../../Reducers/Filters';
import { resetWinesActionCreator } from '../../Reducers/Wines';
import { resetDishesActionCreator } from '../../Reducers/Dishes';
import { resetDrinksActionCreator } from '../../Reducers/Drinks';


interface IRestaurantsListProps {
  restaurants: any;
  onClick: (id: string) => void;
};

const RestaurantsList: React.FC<IRestaurantsListProps> = ({restaurants, onClick}: IRestaurantsListProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFiltersActionCreator());
    dispatch(resetWinesActionCreator());
    dispatch(resetDishesActionCreator());
    dispatch(resetDrinksActionCreator());
  }, [dispatch]);
  
  return (
    <ListContainer className='restaurant-list' height='64.2vh'>
      {
        restaurants.map( (restaurant: any, index: number) => 
          <ListItem key={index} 
            onClick={ () => onClick(restaurant._id)}
          >
            <h5>{restaurant.name}</h5>
          </ListItem>
        )
      }
    </ListContainer>
  )
}

export default RestaurantsList;
