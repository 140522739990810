import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { DishesService } from "../Services/Dishes";
import { Dish } from "../Types";

export interface DishesState {
	data: Dish[] | null;
	dishesTotal: number;
	error: string | null;
}

const InitialDishesState = {
	data: null,
	dishesTotal: 0,
	error: null,
} as DishesState;

export interface GetDishesDataProps {
	clientId: string;
	page: number;
	filters: any;
	restaurantId: string;
}
/* GET CLIENT INFO THUNK  */
export const getDishesData = createAsyncThunk<any, GetDishesDataProps, {}>("dishes/getDishesData", async payload => {
	const { clientId, page, filters, restaurantId } = payload;
	const response = await DishesService.fetchDishes(clientId, page, filters, restaurantId);
	return response;
});

/* REDUCER CREATOR */
const DishesSlice = createSlice({
	name: "dishes",
	initialState: InitialDishesState,
	reducers: {
		setDishes: (_, action: PayloadAction<DishesState>): DishesState => {
			return { ...action.payload };
		},
		resetDishes: (): DishesState => {
			return { ...InitialDishesState };
		},
	},
	extraReducers: {
		[getDishesData.pending.type]: (): DishesState => {
			return InitialDishesState;
		},
		[getDishesData.fulfilled.type]: (state, action): void => {
			state.data = action.payload;
			state.error = null;
		},
		[getDishesData.rejected.type]: (): DishesState => {
			return InitialDishesState;
		},
	},
});

/* ACTION TYPES EXPORTS */
export const { setDishes: setDishesActionCreator, resetDishes: resetDishesActionCreator } = DishesSlice.actions;

export default DishesSlice;
