import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CellarOrder } from "Types";
//import cookie from 'react-cookies';

import { ClientService } from "../Services/Client";

export interface ClientState {
	data: {
		atrasado: boolean;
		clientid: string;
		client_type: string;
		email: string;
		habilitado: boolean;
		id: string;
		languages: string[];
		main_currency: string;
		main_image1_url: string;
		main_image2_url: string;
		main_image3_url: string;
		main_image4_url: string;
		name: string;
		pairings: boolean;
		phone: string;
		whatsapp: string;
		address: string;
		description: string;
		restaurants: any;
		wine_order: CellarOrder;
		wine_sort: boolean;
		client_settings: ClientSettings;
		total_cava: number;
		about_us?: string;
	} | null;
	error: boolean | false;
	isLoading: boolean | false;
}

export interface ClientSettings {
	show_global_selfcategories: boolean;
	decimals?: boolean;
}

const InitialClientState: ClientState = {
	data: null,
	isLoading: false,
	error: false,
};

/* GET CLIENT INFO THUNK  */
export const getClientData = createAsyncThunk("client/getClientData", async (id: string, { rejectWithValue }) => {
	try {
		let response = await ClientService.fetchClientDataByName(id);

		// If above response fails, we dispatch second chance to try with ID as numeric field (mongoDB ID) instead of client_name
		if (response.info === "error") {
			response = await ClientService.fetchClientData(id);
			// If process failed (and backend sent us 500 status), we throw an error to dispatch a rejected status on redux
			if (response.response && response.response.status === 500) {
				throw response;
			}
			response.data = { ...response.data, id };
		}
		return response.data;
	} catch (error) {
		// Dispatch rejected status on redux with error payload
		return rejectWithValue({ message: (error as any).response.msg });
	}
});

/* REDUCER CREATOR */
const clientSlice = createSlice({
	name: "client",
	initialState: InitialClientState,
	reducers: {},
	extraReducers: {
		[getClientData.pending.type]: (state): void => {
			state.isLoading = true;
		},
		[getClientData.fulfilled.type]: (state, action): void => {
			state.data = action.payload;
			state.isLoading = false;
			if (!action.payload) {
				state.error = true;
			}
		},
		[getClientData.rejected.type]: (state): void => {
			state.isLoading = false;
			state.error = true;
		},
	},
});

/* ACTION TYPES EXPORTS */
// export const {} = clientSlice.actions;

export default clientSlice;
