/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import VizSensor from 'react-visibility-sensor';
import _ from 'lodash';

import { AppState } from 'store';
import { ClientURL, Dish, GlobalCategory, DishSelfCategory, ScheduleOptions, ISelfCategory } from 'Types';
import { useFetchDishes } from 'Hooks';
import { setMenuFiltersActionCreator, setScheduleFiltersActionCreator } from 'Reducers/Filters';
import { motion } from 'framer-motion';
import { ListItem, ListContainer, FlexContainer, Loading, Logo } from 'Components/Shared';
import DishListItem from './DishListItem';
import MenuFilters from './MenuFilters';
import DishDetailModal from './DishDetailModal';
import { useTranslation } from 'react-i18next';
import { FilterSubtitle, FilterTitle, Subtitle } from 'Components/Core';
import { flex } from 'Styles/tools';
import { ScheduleFilters } from './ScheduleFilters';
import { getDimensions } from 'Utils/behaviour';
import { getGlobalLanguage } from 'Utils/language.helper';
import { filterDishesByRestaurant } from 'Utils';

const MenuHeader = styled.div<any>`
	background-color: ${({ theme }): any => theme.colors.white};
	padding: 5px 0;
	height: ${({ height = '20%' }): any => height};
	width: 100%;
`;

const SelfCategoriesContainer = styled.div`
	flex-shrink: 0;
`;

const TitleContainer = styled.div`
	${flex('row', 'space-between', 'center', 'nowrap')}
`;

const SCHEDULES_OPTIONS = [
	{ id: 'is_breakfast', label: 'schedules.is_breakfast' },
	{ id: 'is_lunch', label: 'schedules.is_lunch' },
	{ id: 'is_dinner', label: 'schedules.is_dinner' },
];

interface ListDish {
	category?: GlobalCategory;
	selfcategories: ISelfCategory[];
	dishes: Dish[];
}

const SENSOR_INDICATOR_OFFSET = 100; // In px units

const RestaurantMenu: React.FC = () => {
	// * LOCAL HELPERS
	const { clientId, restaurantId, sectionId } = useParams<ClientURL>();
	const { i18n, t } = useTranslation();
	const { search } = useLocation(); // search contains optional query params
	const history = useHistory();
	const dispatch = useDispatch();

	// * REDUX STATE
	const showGlobalSelfCategories = useSelector(
		(store: AppState) => store.client.data?.client_settings?.show_global_selfcategories
	);
	const dishGlobalCategory = useSelector((store: AppState) => store.categories.dishGlobalCategory);
	const categories = useSelector((store: AppState) => store.categories.dish);
	const filters = useSelector((state: AppState) => state.filters.menu);
	const client = useSelector((state: AppState) => state.client.data);

	// * LOCAL STATE
	const globalCategoriesOrder: number[] = [1, 3, 4, 6, 5, 2]; // ! APERITIVO (1), ENTRADA (3), SOPA (4), PRIMER TIEMPO (6), PLATO FUERTE (5), POSTRE (2)
	let selfcategoriesOrder: any = []; 
	if(dishGlobalCategory){
		let len: number =  dishGlobalCategory!.length;
		if(len > 0){
			selfcategoriesOrder = globalCategoriesOrder.map(id => dishGlobalCategory?.find(item => item.Id === id));
		}
	}
	const [filteredDishesByShift, setFilteredDishesByShift] = useState<Array<Dish>>([]);
	const [emptyShifts, setEmptyShifts] = useState<Array<string>>([]);
	const [processedLabels, setProcessedLabels] = useState<any>({
		groupedDishesByCat: [],
		groupedDishesBySelfcat: [],
		localProcessingDishes: true,
	});

	const [usedDishGlobalCategories, setUsedDishGlobalCategories] = useState<GlobalCategory[] | null>(null);
	const [usedDishSelfCategories, setUsedDishSelfCategories] = useState<DishSelfCategory[] | null>(null);
	const [selectedSchedule, setSelectedSchedule] = useState<ScheduleOptions>('is_lunch'); // TODO: make this dynamically when schedules/shifts working (from backoffice)
	const [visibleSection, setVisibleSection] = useState();
	const currentLang = getGlobalLanguage(i18n.language).toUpperCase();
	const categoriesRefs = useRef<any>([]);
	const menuRef = useRef<any>();
	const listItemsRef = useRef<any>();

	const { dishes, fetchingDishes, hasMoreDishes } = useFetchDishes(client ? client.id : '', 1, filters, restaurantId, true);

	const handleFilterChange = useCallback(
		(id: string | number) => dispatch(setMenuFiltersActionCreator({ selfCategory: Number(id) })),
		[dispatch]
	);

	const openDishDetail = (dish: Dish): void => {
		if (client?.client_type === 'hotel') {
			history.push(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}/${dish.id}${search}`);
		} else {
			history.push(`/restaurant/${clientId}/${sectionId}/${dish.id}${search}`);
		}
	};

	const closeDishDetail = (): void => {
		if (client?.client_type === 'hotel') {
			history.replace(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}`);
		} else {
			history.replace(`/restaurant/${clientId}/${sectionId}`);
		}
	};

	const handleScheduleChange = (id: ScheduleOptions): void => {
		setSelectedSchedule(id);
	};

	useEffect(() => {
		// If no empty skip
		if (!emptyShifts.includes(selectedSchedule)) return;

		// Otherwise look for other shift
		const availableOptions = SCHEDULES_OPTIONS.filter(({ id }: any) => !emptyShifts.includes(id));
		if (!availableOptions.length) return;

		setSelectedSchedule(availableOptions[0].id as ScheduleOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emptyShifts]);

	useEffect(() => {
		// * Apply restaurant filters to dishes (only for client type Hotel)
		const filteredDishesByRestaurant = filterDishesByRestaurant(
			client?.client_type ?? '',
			restaurantId,
			dishes.data ?? []
		);

		// * Apply schedule/shift filters to dishes (breakfast, lunch or dinner)
		const filteredDishesByShift = filteredDishesByRestaurant
			? filteredDishesByRestaurant.filter(dish => dish[selectedSchedule])
			: [];

		setFilteredDishesByShift(filteredDishesByShift);

		// Only if there are actually dishes data, we define if current shift is empty to change it
		if (dishes.data && dishes.data.length > 0 && filteredDishesByShift.length === 0) {
			setEmptyShifts(prev => [...prev, selectedSchedule]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, dishes.data, restaurantId, selectedSchedule]);

	useEffect(() => {
		// ! PROCESSING TO GROUP DISHES BY GLOBAL CATEGORY
		// * Group dishes by selfcategory looping throw ordered dish global categories (appetizer, entree, soup, first course, main course, dessert)
		const groupedByCatDishes =
			dishGlobalCategory?.map(({ Id }) => filteredDishesByShift.filter(({ categories }) => categories[0] === Id)) ?? [];

		// * Now we need to iterate over grouped dishes and search its selfcategories to order them by "order" field
		const orderedGroupedCatDishes = groupedByCatDishes.map(group => {
			if (!group.length) return [];

			// Generate a new mapped data for every selfcategory, { <selfcat_id>: [dishes] }
			const selfcategories: any = {};
			for (let idx = 0; idx < group.length; idx++) {
				const dish = group[idx];
				const data = selfcategories[dish.selfcategory.id];
				if (data) selfcategories[dish.selfcategory.id] = [...data, dish]; // If current selfcat is in selfcategories we append it current dish
				if (!data) selfcategories[dish.selfcategory.id] = [dish]; // If current selfcat is not in selfcategories we add current dish as first item
			}

			// Retrieve dishes selfcategories to get "order" and ordering as last step
			const usedSelfcategories = categories?.filter(({ Id }: any) => selfcategories[Id]);
			const orderedSelfcategories = usedSelfcategories?.sort((a, b) => a.order - b.order).map(({ Id }: any) => Id);

			if (!orderedSelfcategories) return [];

			// If there are processed data, we generate a new array of subarrays (dishes) ordered
			const orderedDishes = [];
			for (let idx = 0; idx < orderedSelfcategories.length; idx++) {
				const selfcatId = orderedSelfcategories[idx];
				orderedDishes.push(selfcategories[selfcatId]);
			}

			// Finally, spread subarrays as single array
			return orderedDishes.flat();
		});

		// Final processing to fill in dishes, selfcategories and categories data
		const groupedDishesByCat: ListDish[] = orderedGroupedCatDishes
			.filter(group => group.length) // Remove empty selfcat group
			.map(group => {
				const category = dishGlobalCategory?.find(({ Id }) => Id === group[0].categories[0]);
				const dishes = group.flat();
				const visitedSelfcat: any = {};

				const selfcategories = dishes.map(({ selfcategory }) => {
					const selfcatIdx = selfcategory.id;
					if (visitedSelfcat[selfcatIdx]) return null;
					visitedSelfcat[selfcatIdx] = true;
					return { ...selfcategory };
				});

				return {
					category,
					selfcategories: selfcategories.filter(data => data),
					dishes: group.flat(),
				};
			});

		// ! PROCESSING TO GROUP DISHES BY SELF/SUB CATEGORY
		// * Group dishes by selfcategory looping throw ordered dish global categories (appetizer, entree, soup, first course, main course, dessert)
		const groupedDishesBySelfcat: any = orderedGroupedCatDishes
			.filter(group => group.length) // Remove empty selfcat group
			.map(group => {
				const dishes = group;
				const selfcategories = dishes
					.map(({ selfcategory }) => ({ ...selfcategory })) // Get and spread selfcategory data
					.filter((value, idx, self) => self.findIndex(t => t.id === value.id) === idx); // Remove duplicated selfcategories

				const allSubcategoriesGrouped = selfcategories.map(selfcat => ({
					selfcategory: selfcat,
					dishes: dishes.filter(({ selfcategory }) => selfcategory.id === selfcat.id),
				}));
				return allSubcategoriesGrouped.flat();
			});

		setProcessedLabels({
			groupedDishesByCat,
			groupedDishesBySelfcat,
			localProcessingDishes: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredDishesByShift]);

	// * STATE MANAGEMENT
	useEffect(() => {
		if (showGlobalSelfCategories && usedDishGlobalCategories && usedDishGlobalCategories.length) {
			dispatch(
				setMenuFiltersActionCreator({
					selfCategory: usedDishGlobalCategories[0].Id,
				})
			);
		}
	}, [showGlobalSelfCategories, usedDishGlobalCategories, dispatch]);

	useEffect(() => {
		const matchedCategories = dishGlobalCategory?.filter(({ Id }) => {
			const matchedGlobalCategoryDishes = dishes.data?.map(({ categories }) => categories.includes(Id));
			return matchedGlobalCategoryDishes?.reduce((acc, curr) => acc || curr, false);
		});
		matchedCategories && setUsedDishGlobalCategories(matchedCategories);
	}, [categories, dishGlobalCategory, dishes.data]);

	useEffect(() => {
		if (showGlobalSelfCategories || !usedDishSelfCategories || !selfcategoriesOrder || !dishes.data) return;

		const filteredSelfcategories: any = selfcategoriesOrder
			.map(({ _id }: any) => usedDishSelfCategories.filter(({ category }) => category === _id))
			.flat();

		const [initSelfcategory] = filteredSelfcategories.filter((selfcategory: any) =>
			dishes.data?.some((dish: Dish) => dish[selectedSchedule] && dish.selfcategory.id === selfcategory.Id)
		);

		if (!initSelfcategory) return;

		dispatch(
			setMenuFiltersActionCreator({
				selfCategory: initSelfcategory.Id,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showGlobalSelfCategories, usedDishSelfCategories, dispatch, dishes.data, selectedSchedule]);

	useEffect(() => {
		const matchedCategories = categories?.filter(({ Id }) => {
			const matchedCategoryDishes = dishes.data?.map(({ selfcategory }) => selfcategory.id === Id);
			return matchedCategoryDishes?.reduce((acc, curr) => acc || curr, false);
		});
		matchedCategories &&
			setUsedDishSelfCategories(
				_.orderBy(
					matchedCategories,
					[cat => _.find(dishGlobalCategory, o => o._id === cat.category)?.order, 'order'],
					'asc'
				)
			);
	}, [dishGlobalCategory, categories, dishes.data]);

	useEffect(() => {
		dispatch(setScheduleFiltersActionCreator(selectedSchedule));
	}, [dispatch, selectedSchedule]);

	useEffect(() => {
		// Processing on scroll event
		const handleScroll = (): void => {
			const { offsetBottom: adjustedTopList }: any = getDimensions(menuRef.current) ?? { offsetBottom: null };
			if (!adjustedTopList) return;
			const categoriesRefsLen = categoriesRefs.current.length;

			let selected: any;
			for (let idx = 0; idx < categoriesRefsLen; idx++) {
				const categoryRef = categoriesRefs.current[idx];
				if (!categoryRef) continue; // Validate if category ref is still existing to prevent app crash (when changing schedule shift)
				const { top: categoryCoordY }: any = getDimensions(categoryRef);
				if (categoryCoordY <= adjustedTopList + SENSOR_INDICATOR_OFFSET) {
					selected = categoryRef;
				}
			}
			if (selected && selected !== visibleSection) {
				handleFilterChange(selected.id);
				setVisibleSection(selected.id);
			}
		};

		// Execute closure at first time (when no scroll event is attached and detected)
		handleScroll();

		// If no list items ref is defined we skip the attachment of scroll event
		if (!listItemsRef.current) return;

		// Attach scroll event to list items (dishes) element (ref) to execute handleScroll and get new selected (item at top) category
		const scroller = listItemsRef.current;
		scroller.addEventListener('scroll', handleScroll);
		return (): void => {
			scroller.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleFilterChange, visibleSection, selectedSchedule, menuRef.current, listItemsRef.current]);

	const { groupedDishesByCat, groupedDishesBySelfcat, localProcessingDishes } = processedLabels;

	if (fetchingDishes || localProcessingDishes) {
		return <Loading show logo />;
	}

	if (!fetchingDishes && !localProcessingDishes && dishes.data?.length === 0) {
		return (
			<>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{ height: '100%', flex: 1 }}
				>
					{/* Dishes selfcategories badges */}
					<MenuHeader className="menu-header" ref={menuRef} height="0"></MenuHeader>
					{/* No data found message */}
					<ListContainer padding="0.5rem">
						<FlexContainer direction="column" justify="center" width="90vw">
							<FilterTitle>{t('dishes.noDishes')}</FilterTitle>
						</FlexContainer>
					</ListContainer>
				</motion.div>
			</>
		);
	}

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				style={{ height: '100%', flex: 1 }}
			>
				{/* Dishes selfcategories badges */}
				<MenuHeader className="menu-header" ref={menuRef}>
					<ScheduleFilters handleFilterChange={handleScheduleChange} selected={selectedSchedule} />
					<MenuFilters
						selected={filters.selfCategory}
						categoriesRefs={categoriesRefs}
						options={showGlobalSelfCategories ? groupedDishesByCat : groupedDishesBySelfcat}
					/>
				</MenuHeader>

				{/* Dishes list content */}
				<ListContainer ref={listItemsRef} padding="0 0.5rem" height="80%">
					{/* // ! PROCESSING TO GROUP DISHES BY GLOBAL CATEGORY */}
					{showGlobalSelfCategories && groupedDishesByCat.length === 0 && (
						<FlexContainer direction="column" justify="center" width="90vw">
							<FilterTitle>{t('dishes.noDishesByShift')}</FilterTitle>
						</FlexContainer>
					)}
					{showGlobalSelfCategories &&
						groupedDishesByCat.length > 0 &&
						groupedDishesByCat.map((group: ListDish, groupIdx: number) => {
							return (
								<div key={groupIdx}>
									<TitleContainer id={`${group.category?.Id}`} ref={node => (categoriesRefs.current[groupIdx] = node)}>
										{group.category && (
											<FilterTitle className="title">
												{group.category[`name_${currentLang.toLowerCase()}` as keyof GlobalCategory] ||
													t('common.noInfoAvailableInThisLanguage')}
											</FilterTitle>
										)}
										<Subtitle bold="100" color="black" padding="10px" margin="0.5rem 0 0 0" size="0.9rem">
											{`${group.dishes.length} ${t('common.dishes')}`}
										</Subtitle>
									</TitleContainer>
									{group.selfcategories.map((selfcat: ISelfCategory) => (
										<SelfCategoriesContainer key={selfcat.id}>
											<FilterSubtitle className="selfcategory-subtitle">
												{selfcat[`name${currentLang}` as keyof ISelfCategory]}
											</FilterSubtitle>
											{group.dishes
												.filter((dish: Dish) => dish.selfcategory.id === selfcat.id)
												.map((dish: Dish, index: number) => (
													<ListItem onClick={() => openDishDetail(dish)} key={index}>
														<DishListItem dish={dish} />
													</ListItem>
												))}
										</SelfCategoriesContainer>
									))}
								</div>
							);
						})}

					{/* // ! PROCESSING TO GROUP DISHES BY SELF/SUB CATEGORY */}
					{!showGlobalSelfCategories &&
						groupedDishesBySelfcat.flat().length > 0 &&
						groupedDishesBySelfcat.flat().map((group: any, groupIdx: number) => {
							return (
								<div key={groupIdx}>
									<TitleContainer
										id={`${group.selfcategory.id}`}
										ref={node => (categoriesRefs.current[groupIdx] = node)}
									>
										{group.selfcategory && (
											<FilterTitle className="title">
												{group.selfcategory[`name${currentLang.toUpperCase()}` as keyof ISelfCategory] ||
													t('common.noInfoAvailableInThisLanguage')}
											</FilterTitle>
										)}
										<Subtitle bold="100" color="black" padding="10px" margin="0.5rem 0 0 0" size="0.9rem">
											{`${
												group.dishes.filter((dish: Dish) => dish.selfcategory.id === group.selfcategory.id).length
											} ${t('common.dishes')}`}
										</Subtitle>
									</TitleContainer>
									{group.dishes
										.filter((dish: Dish) => dish.selfcategory.id === group.selfcategory.id)
										.map((dish: Dish, index: number) => (
											<ListItem onClick={() => openDishDetail(dish)} key={index}>
												<DishListItem dish={dish} />
											</ListItem>
										))}
								</div>
							);
						})}

					{/* Visibility sensor to dispatch load more drinks */}
					<VizSensor partialVisibility>
						<FlexContainer direction="column" justify="center" width="100%">
							<Logo size="100px" padding="3rem 1rem" />
							<Loading show={fetchingDishes && hasMoreDishes} />
						</FlexContainer>
					</VizSensor>
				</ListContainer>
			</motion.div>
			<DishDetailModal close={closeDishDetail} />
		</>
	);
};

export default RestaurantMenu;
