export const getDimensions = (element: HTMLElement) => {
  if (!element) return;
  const { height, top, right, bottom, left } = element.getBoundingClientRect();
  const offsetTop = element.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    top,
    right,
    bottom,
    left,
    offsetTop,
    offsetBottom,
  }
}

export const scrollTo = (element: HTMLElement, vertical: ScrollLogicalPosition = "start", horizontal: ScrollLogicalPosition = "start") => {
  if (!element) return;
  element.scrollIntoView({
    behavior: "smooth",
    block: vertical, // Vertical scroll position
    inline: horizontal, // Horizontal scroll position
  });
}