/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";

import { flex } from "Styles/tools";
import { Page, FlexContainer } from "Components/Shared";
import PageContent from "Components/Shared/Page/PageContent";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useParams } from "react-router-dom";
import { ClientType, ClientURL } from "Types";

interface AppDownloadBannerProps {
	isOpen: boolean;
	handleBanner: (state: boolean) => void;
}

const Banner = styled(motion.div)`
	${flex("row", "space-between", "center", "nowrap")}
	width: 100%;
	height: 100%;
	padding: 0 1rem;
	background-color: ${({ theme }) => theme.colors.black};
	transform-origin: top;
	position: relative;
`;

const CloseButton = styled(MdClose)`
	position: absolute;
	top: 50%;
	right: 0.4rem;
	transform: translate(-0.4rem, -50%);
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.red};
`;

const DownloadLink = styled.a<any>`
	padding: 0.5em 1em;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.red};
	border: 0;
	border-radius: ${({ theme }) => theme.rounded};
	margin: ${({ margin = "1rem 0" }) => margin};
	outline: 0;
	text-decoration: none;
`;

const APP_BANNER_VARIANTS = {
	initial: {
		y: 0,
		opacity: 0,
	},
	in: {
		y: 0,
		opacity: 1,
	},
	out: {
		y: -150,
		opacity: 0,
	},
};

const AppDownloadBanner: React.FC<AppDownloadBannerProps> = ({ isOpen, handleBanner }) => {
	const [branchURL, setBranchURL] = useState("");
	const { t } = useTranslation();

	const client = useSelector((store: AppState) => store.client.data);
	const { restaurantId } = useParams<ClientURL>();

	const isClientEnabled = client?.habilitado;
	const isDeadbeatClient = client?.atrasado;

	const name = client ? client.name : null;
	const client_type = client?.client_type;
	const restaurantQuery = client_type === ClientType.HOTEL && restaurantId ? `${restaurantId}` : "";

	useEffect(() => {
		setBranchURL(
			`https://wine-advisor.app.link/C4HYu19Rq9?clientid=${name}&client_type=${client_type}&restaurantid=${restaurantQuery}`
		);
	}, [name, client_type, restaurantQuery]);

	return (
		<AnimatePresence>
			{isOpen && client && isClientEnabled && (
				<Page animated={false} customHeight={8}>
					<PageContent className="page-content-donwload-banner">
						<Banner
							className="app-banner"
							initial="initial"
							animate="in"
							exit="out"
							transition={{ duration: 0.25 }}
							variants={APP_BANNER_VARIANTS}
						>
							<FlexContainer align="center" direction="row" height="100%">
								<DownloadLink className="bold" href={branchURL} target="_blank">
									{t("app-banner.download-app")}
								</DownloadLink>
								{isDeadbeatClient && (
									<DownloadLink as="span" className="bold deadbeat-client-alert" margin="1rem 0.25rem">
										{t("common.deadbeatUser")}
									</DownloadLink>
								)}
							</FlexContainer>
							<CloseButton onClick={(): void => handleBanner(false)} />
						</Banner>
					</PageContent>
				</Page>
			)}
		</AnimatePresence>
	);
};

export default AppDownloadBanner;
