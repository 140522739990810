import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { flex } from '../../../Styles/tools';

export type OptionType = {
  value: string;
  label: string;
}

export type DropdownContainerProps = {
  verticalOffset?: number;
  leftOffset?: number;
}
const DropdownContainer = styled(motion.div)<DropdownContainerProps>`
  width: 100%;
  border-radius: ${({theme}) => theme.radius};
  background: transparent;

  position: absolute;
  top: ${ ({verticalOffset = 0}) => `calc( 70%  + ${verticalOffset}%)` };
  z-index: 1;
  left: ${({leftOffset = 0}) => `${leftOffset}%`};
`

const DropdownDecoration = styled.div`
  height: 8px;
  width: 100%;
  clip-path: polygon(50% 0, 40% 105%, 60% 105%);
  background: ${({theme}) => theme.colors.light_gray};
`

const DropwdownContent = styled.div`
  box-shadow: ${({theme}) => theme.shadow.overlay};
  border-radius: ${({theme}) => `0 0 ${theme.radius} ${theme.radius}`};
`
type OptionBoxType = {
  isSelected?: boolean;
  position?: string;
}
const OptionBox = styled.div<OptionBoxType>`
  ${ flex('row', 'flex-start', 'center', 'nowrap') };
  padding: 6.2px 10px;
  width: 100%;
  color: ${ ({theme}) => theme.colors.text_dark};
  border-bottom: ${ ({position}) => position !== 'last' ? `1px rgba(128, 128, 128, 0.17) solid` : '0'};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.light_gray : theme.colors.white)};
	border-radius: ${({ theme, position }) => (position === "last" ? `0 0 ${theme.radius} ${theme.radius}` : position === "first" ? `${theme.radius} ${theme.radius} 0 0` : `0`)};
`

const OptionText = styled.span`
  color: ${ ({theme}) => theme.colors.text_dark};
`

interface IDropdownProps {
  options: any;
  isOpen: boolean;
  selected: string;
  onChange: (option: OptionType) => void;
};

const Dropdown: React.FC<IDropdownProps & DropdownContainerProps> = ({selected, isOpen, options, onChange, ...props}: IDropdownProps) => {


  const getPosition = (index: number): string => {
    if (index === 0) return 'first'
    if (index === options.length - 1) return 'last';
    return 'normal'
  }

  return (
    <AnimatePresence>
      { isOpen &&
        <DropdownContainer
          className='dropdown-container'
          {...props}
          key="dropwdownWrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: {duration: 0.25}}}
          exit={{ opacity: 0, transition: {duration: 0.25}}}
        >
           <DropdownDecoration className='dropdow-decoration'/>
           <DropwdownContent className='dropdow-content'>
            {
              options.map( (option: OptionType, index: number) =>  
                <OptionBox  
                  className='option-box'  
                  onClick={() => onChange(option)} 
                  position={getPosition(index)}
                  isSelected={selected === option.value}
                  key={option.value}  
                >
                  <OptionText className='option-text'>{option.label}</OptionText>
                </OptionBox> 
              )
            }
            </DropwdownContent> 
        </DropdownContainer>
      }
    </AnimatePresence>

  )
}

export default Dropdown
