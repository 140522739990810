import React, { useState, FormEvent, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowToggle } from '../../Shared';
import { AppThemeColors } from '../../../Styles/Theme';
import { flex } from '../../../Styles/tools';
import Dropdown, { OptionType, DropdownContainerProps } from './Dropdown';
import { useOnClickOutside } from '../../../Hooks';

const SelectContainer = styled.div`
  position: relative;
`;

const SelectInput = styled.div`
  ${ flex('row', 'center', 'center', 'nowrap') }
`;

type SelectSelectionType = { textColor?: AppThemeColors; };
const SelectSelection = styled.span<SelectSelectionType>`
  color: ${ ({theme, textColor = 'white'}) => theme.colors[textColor] };
  min-width: 18px;
`;

interface ISelectProps {
  options: any;
  showArrow?: boolean;
  textColor?: AppThemeColors;
};

const CustomSelect: React.FC<ISelectProps & React.HTMLProps<HTMLInputElement> & DropdownContainerProps> = (props) => {
  const {
    name = 'select-input',
    placeholder,
    value, 
    onChange,
    showArrow = true,
    textColor,
    options,
    leftOffset,
    verticalOffset
  } = props;

  const [isOpen, setisOpen] = useState(false);
  const toggleIsOpen = () => setisOpen(prev => !prev);

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const closeDropdown = useCallback( () =>  setisOpen(false), [] );
  useOnClickOutside(ref, closeDropdown);  

  const searchValueFn = (option: OptionType) => option.value === value;
  const [selected, setselected] = useState({
    value: options.find(searchValueFn) ? options.find(searchValueFn).value: '', 
    label: options.find(searchValueFn) ? options.find(searchValueFn).label: ''
  });

  const onOptionChange = useCallback((option: OptionType) => {
    setselected(option);
    onChange && onChange({currentTarget: {name: name, value: option.value}} as FormEvent<HTMLInputElement>);
  }, [name, onChange])

  // Special process to update local state when value (parent state) changes
  useEffect(() => {
    const newOption = options.find((option: OptionType) => option.value === value);
    if (!newOption) {
      onOptionChange(options[0]);
    }
  }, [onOptionChange, options, value]);

  return (
    <SelectContainer className='select-container' onClick={toggleIsOpen} ref={ref}>
      <SelectInput className='select-input'>
        { showArrow &&
          <ArrowToggle open={isOpen}  textColor={textColor}/>
        }
        <SelectSelection textColor={textColor} className='select-selection'>
          { selected ? selected.label : placeholder }
        </SelectSelection>
      </SelectInput>
      <Dropdown isOpen={isOpen} options={options} onChange={onOptionChange} selected={selected.value} leftOffset={leftOffset} verticalOffset={verticalOffset} />
    </SelectContainer>
  )
}

export default CustomSelect;
