import React from "react";
import styled from "styled-components";

const FilterTitle = styled.h1`
	padding-left: 10px;
	margin: 0.5rem 0 0 0;
	font-size: 1.35rem;
	text-transform: capitalize;
	font-family: CenturyGothicBold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const index = ({ children }: any) => {
	return <FilterTitle>{children}</FilterTitle>;
};

export default index;
