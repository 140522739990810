import axios from "axios";
import { CellarOrder } from "Types";
import { AUTHORIZATION, URL_INTEGRAL } from "env";
import { isUsingCellarFilters } from "Utils";

const SERVICE_ID = "@WinesService";
const DEBUGGING = false;
const MAX_LIMIT = 5000; // Arbitrarily defined to fetch all wines to build the filters

// Mapping client source order field to used in API endpoint
const getOrderField = (field: CellarOrder, lang: string): string => {
	if (field === "name") return `name${lang}`;
	if (field === "price") return `sellPrice`;
	return field;
};

// Mapping client source type sort to standard for RESTful API
const getTypeSort = (sort: boolean): string => {
	return sort ? "" : "-";
};

export const fetchWines = async (
	client: string,
	page: number,
	stockFilter: any,
	lang: string,
	limit = 20,
	winesTotal: any = MAX_LIMIT
): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID}`);
	DEBUGGING &&
		console.log(`${SERVICE_ID}`, {
			params: {
				client,
				page,
				stockFilter,
			},
		});
	const isUsingFilters = isUsingCellarFilters(stockFilter);

	if (isUsingFilters) {
		limit = winesTotal;
	}

	try {
		const config = { headers: { authorization: `${AUTHORIZATION}` } };

		const typeQuery = stockFilter.type !== "" ? `&type${lang}=${stockFilter.type}` : "";
		const varietyquery = stockFilter.varietal !== "" ? `&varietal${lang}=${stockFilter.varietal}` : "";
		const regionQuery = stockFilter.region !== "" ? `&region${""}=${stockFilter.region}` : "";
		const subregionQuery = stockFilter.subregion !== "" ? `&subregion${""}=${stockFilter.subregion}` : "";
		const countryQuery = stockFilter.country !== "" ? `&country${lang}=${stockFilter.country}` : "";
		const byGlassQuery = stockFilter.selling_by !== "" ? `&byGlass=true` : "";
		const sortQuery = `${getTypeSort(stockFilter.wine_sort)}${getOrderField(stockFilter.wine_order, lang)}`;

		const filter = "&outOfStock=false&archived=false&available=true";
		const sort = `&sort=${sortQuery}`;
		const query = `page=${page}&limit=${limit}${typeQuery}${varietyquery}${regionQuery}${subregionQuery}${countryQuery}${filter}${sort}${byGlassQuery}`;

		const response = await axios.get(`${URL_INTEGRAL}/v2/clients/wines/${client}?${query}`, config);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error(`${SERVICE_ID}: `, error);
		DEBUGGING && console.groupEnd();

		return error;
	}
};

export const WinesService = {
	fetchWines,
};
