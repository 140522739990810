import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Logo from "./Logo";
import { flex } from "../../Styles/tools";
import { Subtitle } from "Components/Core";

const LoadingContainer = styled.div`
	${flex("column", "center", "center", "nowrap")}
	width: 100%;
	height: 100%;
`;

interface LoadingProps {
	show: boolean | null;
	logo?: boolean;
	size?: string;
	textSize?: string;
	padding?: string;
}

const Loading: React.FC<LoadingProps> = ({ show, size, logo = false, textSize = "1.2rem", padding }: LoadingProps) => {
	const { t } = useTranslation();

	return (
		<>
			{show && (
				<LoadingContainer>
					{logo && <Logo size={size} padding={padding} />}
					<Subtitle color="black" align="center" margin="0 0 0.5rem 0" size={textSize}>
						{`${t("common.loading")}...`}
					</Subtitle>
				</LoadingContainer>
			)}
		</>
	);
};

export default Loading;
