import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.scss';

import './i18n';
import store from './store';
import { GlobalStyle } from './Styles/GlobalStyles';
import { AppTheme } from './Styles/Theme';
import "../node_modules/flag-icons/css/flag-icons.min.css"


ReactDOM.render(
  <>
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <ThemeProvider theme={AppTheme}>
          <GlobalStyle/>
          <App />
        </ThemeProvider>
      </Provider> 
    </Suspense>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
