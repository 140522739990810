import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RestaurantService } from 'Services/Restaurant';
import { GlobalCategory, DishSelfCategory, DrinkSelfCategory, WineCategoriesType } from 'Types';


/* REDUCER CREATOR */
export interface IgetDrinkSelfCategories {
  clientUsername: string;
  restaurantId: string;
};

export const getCellarData = createAsyncThunk( 'categories/getCellarData',
    async (clienId: string) =>  {
      const response = await RestaurantService.fetchCellarData(clienId);
      return response.data;
    }
);

export const getDrinkGlobalCategories = createAsyncThunk<any, {clientUsername: string;}, {}>( 'categories/getDrinkGlobalCategories',
    async (payload) =>  {
      const { clientUsername } = payload;
      const response = await RestaurantService.fetchDrinkGlobalCategories(clientUsername);
      return response.data;
    }
);

export const getDrinkSelfCategories = createAsyncThunk<any,IgetDrinkSelfCategories, {}>( 'categories/getDrinkSelfCategories',
  async (payload) =>  {
    const { clientUsername, restaurantId } = payload;

    const response = await RestaurantService.fetchSelfDrinkCategories(clientUsername, restaurantId);
    return response.data;
  }
);

export const getDishGlobalCategories = createAsyncThunk<any, {clientUsername: string}, {}>( 'categories/getDishGlobalCategories',
  async (payload) =>  {
    const { clientUsername } = payload;

    const response = await RestaurantService.fetchDishGlobalCategories(clientUsername);
    return response.data;
  }
);

export const getDishSelfCategories = createAsyncThunk<any,{clientUsername: string}, {}>( 'categories/getDishSelfCategories',
    async (payload) =>  {
      const { clientUsername } = payload;

      const response = await RestaurantService.fetchSelfDishCategories(clientUsername);
      return response.data;
    }
);


export interface CategoriesState {
  wine: WineCategoriesType | null;
  dishGlobalCategory: GlobalCategory[] | null;
  dish: DishSelfCategory[] | null;
  drinkGlobalCategory: GlobalCategory[] | null;
  drink: DrinkSelfCategory[] | null;
  isLoading: boolean;
  error: boolean;
};
const InitialCategoriesState: CategoriesState = {
  wine: null,
  dishGlobalCategory: null,
  dish: null,
  drinkGlobalCategory: null,
  drink: null,
  isLoading: false,
  error: false,
};
const categoriesSlice = createSlice({
  name: 'categories',
  initialState: InitialCategoriesState,
  reducers: {
    setWineCategories: (state: CategoriesState, action: PayloadAction<any>) => {
      state.wine = action.payload
    },
    setDishCategories: (state: CategoriesState, action: PayloadAction<DishSelfCategory[]>) => {
      const sortedSelfcategories = action.payload.sort((a, z) => a.superId - z.superId); // Sort all selfCategories by its id
      // Custom target order for selfCats
      const selfcategoriesOrder: any = [
        { name: 'appetizer', id: 1 },
        { name: 'entree', id: 3 },
        { name: 'soup', id: 4 },
        { name: 'first course', id: 6 },
        { name: 'main course', id: 5 },
        { name: 'dessert', id: 2 },
      ];
      const filteredSelfcategories: any = selfcategoriesOrder.map(({ name, id }: any) =>
        sortedSelfcategories.filter(
          ({ superId }) => superId === id
          /*({ name_EN, superId }) => nameEN.toLowerCase() === name || superId === id*/
        )
      ); // search and get selfCats that matches with selfcategoriesOrder
      state.dish = filteredSelfcategories.flat();
    },
    setDrinkCategories: (state: CategoriesState, action: PayloadAction<DrinkSelfCategory[]>) => {
      state.drink = action.payload
    },
    resetCategories: (state) => {
      return {...InitialCategoriesState}
    },
  },
  extraReducers: {
    [getDrinkSelfCategories.pending.type]: (state, action) => {
      state.drink = null;
      state.isLoading = true;
      state.error = false;
    },
    [getDrinkSelfCategories.fulfilled.type]: (state, action) => {
      state.drink = action.payload;
      state.isLoading = false;
      state.error = false;
    },
    [getDrinkSelfCategories.rejected.type]: (state, action) => {
      state.drink = null;
      state.isLoading = false;
      state.error = true;
    },
    [getCellarData.pending.type]: (state, action) => {
      state.wine = null;
      state.isLoading = true;
      state.error = false;
    },
    [getCellarData.fulfilled.type]: (state, action) => {      
      state.wine = {
        ...action.payload,
        countryEN: action.payload.countryEN.sort(),
        countryES: action.payload.countryES.sort(),
        countryPT: action.payload.countryPT.sort(),
        regionEN: action.payload.regionEN.sort(),
        regionES: action.payload.regionES.sort(),
        regionPT: action.payload.regionPT.sort(),
        subregionEN: action.payload.subregionEN.sort(),
        subregionES: action.payload.subregionES.sort(),
        subregionPT: action.payload.subregionPT.sort(),
        typeEN: action.payload.typeEN.sort(),
        typeES: action.payload.typeES.sort(),
        typePT: action.payload.typePT.sort(),
        varietalEN: action.payload.varietalEN.sort(),
        varietalES: action.payload.varietalES.sort(),
        varietalPT: action.payload.varietalPT.sort(),
      };
      state.isLoading = false;
      state.error = false;
    },
    [getCellarData.rejected.type]: (state, action) => {
      state.wine = null;
      state.isLoading = false;
      state.error = true;
    },
    [getDishGlobalCategories.pending.type]: (state, action) => {
      state.dishGlobalCategory = null;
      state.isLoading = true;
      state.error = false;
    },
    [getDishGlobalCategories.fulfilled.type]: (state, action) => {
      const categoriesOrder: any = { '1': 1,  '2': 6, '3': 2, '4': 3, '5': 5, '6': 4 };
      state.dishGlobalCategory = _.map(
          _.orderBy(action.payload, o => categoriesOrder[o.Id.toString()] || -1, 'asc'),
          (o, index) => ({...o, order: index + 1})
      );
      state.isLoading = false;
      state.error = false;
    },
    [getDishGlobalCategories.rejected.type]: (state, action) => {
      state.dishGlobalCategory = null;
      state.isLoading = false;
      state.error = true;
    },
    [getDishSelfCategories.pending.type]: (state, action) => {
      state.dish = null;
      state.isLoading = true;
      state.error = false;
    },
    [getDishSelfCategories.fulfilled.type]: (state, action) => {
      state.dish = action.payload;
      state.isLoading = false;
      state.error = false;
    },
    [getDishSelfCategories.rejected.type]: (state, action) => {
      state.dish = null;
      state.isLoading = false;
      state.error = true;
    },
    [getDrinkGlobalCategories.pending.type]: (state, action) => {
      state.drinkGlobalCategory = null;
      state.isLoading = true;
      state.error = false;
    },
    [getDrinkGlobalCategories.fulfilled.type]: (state, action) => {
      const categoriesOrder: any = { '1': 1,  '2': 2, '3': 3, '4': 4, '5': 5, '6': 6 };
      state.drinkGlobalCategory = _.map(
          _.orderBy(action.payload, o => categoriesOrder[o.Id.toString()] || -1, 'asc'),
          (o, index) => ({...o, order: index + 1})
      );
      state.isLoading = false;
      state.error = false;
    },
    [getDrinkGlobalCategories.rejected.type]: (state, action) => {
      state.drinkGlobalCategory = null;
      state.isLoading = false;
      state.error = true;
    },
  }
});

/* ACTION TYPES EXPORTS */
export const {
  setWineCategories: setWineCategoriesActionCreator,
  setDishCategories: setDishCategoriesActionCreator,
  setDrinkCategories: setDrinkCategoriesActionCreator,
  resetCategories: resetCategoriesActionCreator
} = categoriesSlice.actions;

export default categoriesSlice;
