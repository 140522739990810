import React, { useState, ReactNode } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { flex } from "../../../../Styles/tools";
import { ArrowToggle, FlexContainer } from "../../../../Components/Shared";
import { AppTheme } from "../../../../Styles/Theme";

const FilterSelectorContainer = styled.div`
	${flex("column", "flex-start", "flex-start", "nowrap")}
	border-bottom: 1px solid ${({ theme }) => theme.colors.placeholder};
	padding: 15px 0;
	width: 100%;
`;

const OptionsContainer = styled(motion.div)`
	width: 100%;
	margin: 1rem 0;
`;

interface FilterSelectorProps {
	title: string;
	children: ReactNode;
}
const FilterSelector: React.FC<FilterSelectorProps> = ({ title, children }) => {
	const [isOpen, setisOpen] = useState(false);

	const toggleContent = () => setisOpen(prev => !prev);

	return (
		<FilterSelectorContainer className="filter-selector-container" onClick={toggleContent}>
			<FlexContainer shrink={0}>
				<ArrowToggle open={isOpen} textColor="red" style={{ padding: 0 }} />
				<h4 style={{ color: AppTheme.colors.white, fontFamily: "CenturyGothicBold" }}>{title}</h4>
			</FlexContainer>
			<AnimatePresence exitBeforeEnter>
				{isOpen && (
					<OptionsContainer
						className="options-container"
						initial={{ scaleY: 0 }}
						animate={{ scaleY: 1 }}
						exit={{
							scaleY: 0,
							transition: {
								delay: 0.1,
								duration: 0.2,
							},
						}}
						transition={{ duration: 0.2 }}
						style={{ originY: 0 }}>
						<motion.div
							initial={{ x: -500 }}
							animate={{ x: 0 }}
							exit={{
								x: -500,
								transition: {
									delay: 0,
									duration: 0.2,
								},
							}}
							transition={{ delay: 0.1, duration: 0.2 }}
							style={{ marginLeft: "1em" }}>
							{children}
						</motion.div>
					</OptionsContainer>
				)}
			</AnimatePresence>
		</FilterSelectorContainer>
	);
};

export default FilterSelector;
