import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VizSensor from "react-visibility-sensor";
import _ from "lodash";

import { AppState } from "store";
import { ClientURL, Drink, DrinkSelfCategory, GlobalCategory } from "Types";
import { useFetchDrinks } from "Hooks";
import { setDrinksFiltersActionCreator } from "Reducers/Filters";
import { motion } from "framer-motion";
import { ListItem, ListContainer, FlexContainer, Loading, Logo } from "Components/Shared";
import DrinkListItem from "./DrinkListItem";
import DrinksFilters from "./DrinksFilters";
import DrinkDetailModal from "./DrinkDetailModal";
import { useTranslation } from "react-i18next";
import { FilterTitle, FilterSubtitle, Subtitle } from "Components/Core";
import { flex } from "Styles/tools";
import { getDimensions } from "Utils/behaviour";
import { getGlobalLanguage } from "Utils/language.helper";
import { filterDrinksByRestaurant } from "Utils";

const DrinksHeader = styled.div<any>`
	background-color: ${({ theme }) => theme.colors.white};
	padding: 5px 0;
	height: ${({ height = "10%" }) => height};
	width: 100%;
`;

const SelfCategoriesContainer = styled.div`
	flex-shrink: 0;
`;

const TitleContainer = styled.div`
	${flex("row", "space-between", "center", "nowrap")}
`;

const SENSOR_INDICATOR_OFFSET = 100; // In px units

const RestaurantDrinks: React.FC = () => {
	// * REDUX STATE
	const client = useSelector((state: AppState) => state.client.data);
	const showGlobalSelfCategories = useSelector(
		(store: AppState) => store.client.data?.client_settings?.show_global_selfcategories
	);
	const drinkGlobalCategory = useSelector((store: AppState) => store.categories.drinkGlobalCategory);
	const categories = useSelector((state: AppState) => state.categories.drink);
	const filters = useSelector((state: AppState) => state.filters.drinks);

	// * LOCAL STATE
	const [usedCategories, setUsedCategories] = useState<DrinkSelfCategory[] | null>(null);
	const [usedDrinkGlobalCategories, setUsedDrinkGlobalCategories] = useState<GlobalCategory[] | null>(null);
	const [visibleSection, setVisibleSection] = useState();

	// * LOCAL HELPERS
	const categoriesRefs = useRef<any>([]);
	const menuRef = useRef<any>();
	const listItemsRef = useRef<any>();

	const { clientId, restaurantId, sectionId } = useParams<ClientURL>();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation(); // search contains optional query params
	const currentLang = getGlobalLanguage(i18n.language).toLocaleLowerCase();

	const { drinks, fetchingDrinks, hasMoreDrinks } = useFetchDrinks(
		client ? client?.name : null,
		1,
		filters,
		restaurantId
	);
	
	let localProcessingDrinks = fetchingDrinks;

	// * LOCAL FUNCTIONS
	const handleFilterChange = useCallback(
		(id: string | number) => {
			dispatch(setDrinksFiltersActionCreator({ selfCategory: id }));
		},
		[dispatch]
	);

	const openDrinkDetail = (drink: Drink) => {
		if (client?.client_type === "hotel") {
			history.push(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}/${drink._id}${search}`);
		} else {
			history.push(`/restaurant/${clientId}/${sectionId}/${drink._id}${search}`);
		}
	};

	const closeDrinkDetail = () => {
		if (client?.client_type === "hotel") {
			history.replace(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId}`);
		} else {
			history.replace(`/restaurant/${clientId}/${sectionId}`);
		}
	};

	// * Apply restaurant filters to drinks (only for client type Hotel)
	localProcessingDrinks = true;
	const filteredDrinksByRestaurant = filterDrinksByRestaurant(
		client?.client_type ?? "",
		restaurantId,
		drinks.data ?? []
	);

	const filteredDrinks = filteredDrinksByRestaurant ? filteredDrinksByRestaurant : [];
	
	const selfcatDrinks = filteredDrinks.map(({ selfdrinkcategories }) => {     
        return _.reduce(
            selfdrinkcategories, (collection, selfcat) => {
                let categori = categories?.find(({ _id }) => _id === selfcat)
                if(categori){
                    return collection = [...collection, categori ]
                }
                return collection   
            },
        [] as any);
    })

	const globalcatDrinks = selfcatDrinks
		.map(group => group.map(({ drinkcategory }: any) => drinkGlobalCategory?.find(({ _id }) => _id === drinkcategory)))
		.flat()
		.filter((value, idx, self) => self.findIndex(t => t?._id === value?._id) === idx)
		.sort((a: any, b: any) => a?.order - b?.order);

	// ! PROCESSING TO GROUP DISHES BY GLOBAL CATEGORY
	// * Group drinks by selfcategory looping throw ordered drink global categories (appetizer, entree, soup, first course, main course, dessert)
	const groupedDrinksByCat = globalcatDrinks.map(cat => {
		const categoryId = cat?._id;
		const selfcategories = categories?.filter(
			({ _id, drinkcategory }) =>
				drinkcategory === categoryId && selfcatDrinks.flat().some(selfcat => selfcat?._id === _id)
		);
		const drinks = filteredDrinks.filter(({ selfdrinkcategories }) =>
			selfdrinkcategories.some(id => selfcategories?.find(({ _id }) => _id === id))
		);
		return {
			category: cat,
			selfcategories,
			drinks,
		};
	});

	// ! PROCESSING TO GROUP DISHES BY SELF/SUB CATEGORY
	const groupedDrinksBySelfcat = globalcatDrinks.map(cat => {
		const selfcategories = categories?.filter(
			({ _id, drinkcategory }) =>
				drinkcategory === cat?._id &&
				filteredDrinks.some(({ selfdrinkcategories }) => selfdrinkcategories.includes(_id))
		);
		const drinks = filteredDrinks.filter(({ selfdrinkcategories }) =>
			selfcategories?.some(({ _id }) => selfdrinkcategories?.includes(_id))
		);
		const allSubcategoriesGrouped = selfcategories?.map(selfcat => ({
			selfcategory: selfcat,
			drinks: drinks.filter(({ selfdrinkcategories }) => selfdrinkcategories.includes(selfcat._id)),
		}));

		return allSubcategoriesGrouped?.flat();
	});

	localProcessingDrinks = false;

	// * STATE MANAGEMENT
	useEffect(() => {
		if (showGlobalSelfCategories && usedDrinkGlobalCategories && usedDrinkGlobalCategories.length) {
			dispatch(
				setDrinksFiltersActionCreator({
					selfCategory: usedDrinkGlobalCategories[0]._id,
				})
			);
		}
	}, [showGlobalSelfCategories, usedDrinkGlobalCategories, dispatch]);

	useEffect(() => {
		const matchedCategories = _.flow(
			() =>
				categories?.filter(({ _id }) => {
					const matchedCategoryDrinks = drinks.data?.map(({ selfdrinkcategories }: any) =>
						selfdrinkcategories.includes(_id)
					);
					return matchedCategoryDrinks?.reduce((acc: boolean, curr: boolean) => acc || curr, false);
				}),
			selfCategories =>
				drinkGlobalCategory?.filter(
					({ _id }) => _.findIndex(selfCategories, ({ drinkcategory }) => drinkcategory === _id) !== -1
				)
		)();
		matchedCategories && setUsedDrinkGlobalCategories(matchedCategories);
	}, [categories, drinkGlobalCategory, drinks.data]);

	useEffect(() => {
		if (!showGlobalSelfCategories && usedCategories && usedCategories.length && groupedDrinksBySelfcat) {
			const orderedSelfcats = groupedDrinksBySelfcat.flat().map(({ selfcategory }: any) => selfcategory);
			dispatch(setDrinksFiltersActionCreator({ selfCategory: orderedSelfcats[0]._id }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showGlobalSelfCategories, usedCategories, dispatch]);

	// Filter drink selfcategories that is used at least in one drink (prevent showing empty/unused selfcategory badge)
	useEffect(() => {
		const matchedCategories = categories?.filter(({ _id }) => {
			const matchedCategoryDrinks = drinks.data?.map(({ selfdrinkcategories }: any) =>
				selfdrinkcategories.includes(_id)
			);
			return matchedCategoryDrinks?.reduce((acc: boolean, curr: boolean) => acc || curr, false);
		});
		matchedCategories &&
			setUsedCategories(
				_.orderBy(
					matchedCategories,
					[cat => _.find(drinkGlobalCategory, o => o._id === cat.drinkcategory)?.order, "order"],
					"asc"
				)
			);
	}, [categories, drinkGlobalCategory, drinks.data]);

	useEffect(() => {
		// Processing on scroll event
		const handleScroll = () => {
			const { offsetBottom: adjustedTopList }: any = getDimensions(menuRef.current);
			const categoriesRefsLen = categoriesRefs.current.length;

			let selected: any;
			for (let idx = 0; idx < categoriesRefsLen; idx++) {
				const categoryRef = categoriesRefs.current[idx];
				if (!categoryRef) continue; // Validate if category ref is still existing to prevent app crash (when changing schedule shift)
				const { top: categoryCoordY }: any = getDimensions(categoryRef);
				if (categoryCoordY <= adjustedTopList + SENSOR_INDICATOR_OFFSET) {
					selected = categoryRef;
				}
			}

			if (selected && selected.id !== visibleSection) {
				handleFilterChange(selected.id);
				setVisibleSection(selected.id);
			}
		};

		// Execute closure at first time (when no scroll event is attached and detected)
		handleScroll();

		// If no list items ref is defined we skip the attachment of scroll event
		if (!listItemsRef.current) return;

		// Attach scroll event to list items (dishes) element (ref) to execute handleScroll and get new selected (item at top) category
		const scroller = listItemsRef.current;
		scroller.addEventListener("scroll", handleScroll);
		return (): void => {
			scroller.removeEventListener("scroll", handleScroll);
		};
	}, [handleFilterChange, visibleSection]);

	// * COMPONENT RENDERING
	if (fetchingDrinks || localProcessingDrinks) {
		return <Loading show logo />;
	}


	if (!fetchingDrinks && !localProcessingDrinks && !groupedDrinksByCat.length && !groupedDrinksBySelfcat.length) {
		return (
			<>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{ height: "100%", flex: 1 }}>
					{/* Dishes selfcategories badges */}
					<DrinksHeader className="menu-header" ref={menuRef} height="0"></DrinksHeader>
					{/* No data found message */}
					<ListContainer padding="0.5rem">
						<FlexContainer direction="column" justify="center" width="90vw">
							<FilterTitle>{t("common.noDataFound")}</FilterTitle>
						</FlexContainer>
					</ListContainer>
				</motion.div>
			</>
		);
	}

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.3 }}
				exit={{ opacity: 0 }}
				style={{ height: "100%", flex: 1 }}>
				{/* Drinks selfcategories badges */}
				<DrinksHeader className="menu-header" ref={menuRef}>
					<DrinksFilters
						selected={filters.selfCategory}
						categoriesRefs={categoriesRefs}
						options={showGlobalSelfCategories ? groupedDrinksByCat : groupedDrinksBySelfcat}
					/>
				</DrinksHeader>

				<ListContainer ref={listItemsRef} padding="0 0.5rem" height="90%">
					{/* // ! PROCESSING TO GROUP DRINKS BY GLOBAL CATEGORY */}
					{showGlobalSelfCategories &&
						groupedDrinksByCat.map((group: any, groupIdx: number) => (
							<div key={groupIdx}>
								<TitleContainer
									id={`${group.category._id}`}
									ref={(node): HTMLDivElement | null => (categoriesRefs.current[groupIdx] = node)}>
									{group.category && (
										<FilterTitle>
											{group.category[`name_${currentLang}`] || t("common.noInfoAvailableInThisLanguage")}
										</FilterTitle>
									)}
									<Subtitle bold="100" color="black" padding="10px" margin="0.5rem 0 0 0" size="0.9rem">
										{`${group.drinks.length} ${t("common.drinks").toLocaleLowerCase()}`}
									</Subtitle>
								</TitleContainer>
								{group.selfcategories.map((selfcat: any) => (
									<SelfCategoriesContainer key={selfcat._id}>
										<FilterSubtitle>{selfcat[`name_${currentLang.toLowerCase()}`]}</FilterSubtitle>
										{group.drinks
											.filter((drink: Drink) => drink.selfdrinkcategories.includes(selfcat._id))
											.map((drink: Drink, index: number) => (
												<ListItem onClick={() => openDrinkDetail(drink)} key={index}>
													<DrinkListItem drink={drink} />
												</ListItem>
											))}
									</SelfCategoriesContainer>
								))}
							</div>
						))}

					{/* // ! PROCESSING TO GROUP DISHES BY SELF/SUB CATEGORY */}
					{!showGlobalSelfCategories &&
						groupedDrinksBySelfcat.flat().map((group: any, groupIdx: number) => (
							<div key={groupIdx}>
								<TitleContainer
									id={`${group.selfcategory._id}`}
									ref={(node): HTMLDivElement | null => (categoriesRefs.current[groupIdx] = node)}>
									{group.selfcategory && (
										<FilterTitle className="title">
											{group.selfcategory[`name_${currentLang.toLowerCase()}`] ||
												t("common.noInfoAvailableInThisLanguage")}
										</FilterTitle>
									)}
									<Subtitle bold="100" color="black" padding="10px" margin="0.5rem 0 0 0" size="0.9rem">
										{`${
											group.drinks.filter((drink: Drink) => drink.selfdrinkcategories.includes(group.selfcategory._id))
												.length
										} ${t("common.drinks")}`}
									</Subtitle>
								</TitleContainer>
								{group.drinks
									.filter((drink: Drink) => drink.selfdrinkcategories.includes(group.selfcategory._id))
									.map((drink: Drink, idx: number) => (
										<ListItem onClick={() => openDrinkDetail(drink)} key={idx}>
											<DrinkListItem drink={drink} />
										</ListItem>
									))}
							</div>
						))}

					{/* Visibility sensor to dispatch load more drinks */}
					<VizSensor partialVisibility>
						<FlexContainer direction="column" justify="center" width="100%">
							<Logo size="100px" padding="3rem 1rem" />
							<Loading show={fetchingDrinks && hasMoreDrinks} />
						</FlexContainer>
					</VizSensor>
				</ListContainer>
			</motion.div>
			<DrinkDetailModal close={closeDrinkDetail} />
		</>
	);
};

export default RestaurantDrinks;
