import styled from "styled-components";
import { AppThemeColors } from "../../Styles/Theme";

type TitleType = {
	margin?: string;
	color?: AppThemeColors;
	width?: string;
	align?: string;
	wrap?: string;
};

const Title = styled.h1<TitleType>`
	color: ${({ theme, color = "white" }): any => theme.colors[color]};
	margin: ${({ margin = "0" }): any => margin};
	width: ${({ width = "auto" }): any => width};
	text-align: ${({ align = "start" }): any => align};
	overflow-wrap: ${({ wrap = "normal" }): any => wrap};
`;

export default Title;
