import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

import { flex } from "Styles/tools";
import WineDetailPage from "./WineDetailPage";
import { ClientURL, Wine } from "Types";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface IWineDetailModalProps {
	close: () => void;
}

const Overlay = styled(motion.div)`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	${flex("column", "flex-start", "flex-start", "nowrap")}
`;

const WineDetailModal: React.FC<IWineDetailModalProps> = ({ close }: IWineDetailModalProps) => {
	const { itemId, wineId } = useParams<ClientURL>();
	const location = useLocation();
	const wines = useSelector((store: AppState) => store.wines.data);
	let wineID = itemId;
	if(location.pathname.includes("menu"))
		wineID = wineId;

	const wine = findWineForDetails(wineID, wines);

	return ReactDOM.createPortal(
		<>
			{wineID && (
				<Overlay
					className="overlay"
					initial={{ x: "125vw" }}
					animate={{ x: 0 }}
					transition={{ duration: 0.3 }}
					exit={{ x: "125vw" }}
				>
					<WineDetailPage close={close} wine={wine} idWine={wineID} />
				</Overlay>
			)}
		</>,
		document.getElementById("portal") as Element
	);

	function findWineForDetails(idWine: string | undefined, wines: Wine[] | null){
		if(idWine && wines && wines.length > 0)
			return wines.find(wine => wine.id.toString() === idWine + "") ?? null;
		return null;
	};
};

export default WineDetailModal;
