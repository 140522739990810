import React from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

import { flex } from '../../../../Styles/tools';

const FilterBadgeContent = styled.div`
  ${ flex('row', 'flex-start', 'center', 'nowrap') }
`

interface IFilterBadgeProps {
  label: string;
  clear: (id: string) => void;
  id: string;
}
const FilterBadge: React.FC<IFilterBadgeProps> = ({label, clear, id}: IFilterBadgeProps) => {
  return (
    <FilterBadgeContent className='filter-badge-content'>
      <span className='bold' style={{whiteSpace: 'nowrap'}}>{label}</span>
      <MdClose style={{fontSize: '1em', marginLeft: '5px', marginTop: '3%'}} onClick={ () => clear(id) }/>
    </FilterBadgeContent>
  )
}

export default FilterBadge
