import { fetchDrinks } from "Services/Drinks";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { setDrinksActionCreator } from 'Reducers/Drinks';
import { getDrinkGlobalCategories, getDrinkSelfCategories } from 'Reducers/Categories';

export default function useFetchDrinks(clientid: string | null, page: number, drinksFilters: any, restaurantId: string) {
  const drinks = useSelector( (store: AppState) => store.drinks);
  const clientUsername = useSelector( (store: AppState) => store.client.data?.clientid);
  const dispatch = useDispatch();

  const [ fetchingDrinkGlobalCategories, setFetchingDrinkGlobalCategories ] = useState(false);
  const [ fetchingDrinksCategoriesData, setFetchingDrinksCategoriesData ] = useState(true);
  const [ drinksError, setError ] = useState(false);
  const [ hasMoreDrinks, setHasMoreDrinks] = useState(false);
  const [ fetchingDrinks, setFetchingDrinks] = useState(false);

  useEffect( () => {
    const getDrinks = async() => {
      if (!clientid) return;

      try {
        setFetchingDrinks(true);
        setError(false);

        const response = await fetchDrinks(clientid, page, drinksFilters, restaurantId);

        const parsedList = response.data;
        const drinksInStore = drinks.data ? drinks.data : [];

        dispatch(setDrinksActionCreator({data: [...drinksInStore, ...parsedList], error: null}));
        setFetchingDrinks(false);
        setHasMoreDrinks( drinks.data ? drinks.data.length < response.results_number : true );
      } catch(e) {
        setError(true);
        setFetchingDrinks(false);
        console.log(e);
      }
    }
    !drinks.data && getDrinks();

  }, [page, clientid, drinksFilters, drinks.data, restaurantId, dispatch]);

  const fetchDrinkGlobalCategories = useCallback(async () =>  {
    if (!clientUsername) return;
    await dispatch(getDrinkGlobalCategories({clientUsername}));
    setFetchingDrinkGlobalCategories(false);
  }, [dispatch, clientUsername]);

  const fetchSelfDrinkCategories = useCallback(async () =>  {
    if (!clientUsername) return;
    await dispatch(getDrinkSelfCategories({clientUsername, restaurantId}));
    setFetchingDrinksCategoriesData(false);
  }, [dispatch, clientUsername, restaurantId]);

  return {
    drinksError,
    drinks,
    fetchingDrinks,
    hasMoreDrinks,
    fetchDrinkGlobalCategories,
    fetchingDrinkGlobalCategories,
    fetchingDrinksCategoriesData,
    fetchSelfDrinkCategories
  };
}
