import axios from "axios";
import { AUTHORIZATION, URL_INTEGRAL } from "env";

const SERVICE_ID = "@SelectionsService";
const DEBUGGING = false;

export const fetchRestaurantSelections = async (clientName: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchRestaurantSelections`);
	const CONFIG = { headers: { authorization: `${AUTHORIZATION}` } };

	try {
		const response = await axios.get(`${URL_INTEGRAL}/v2/clients/selections/${clientName}`, CONFIG);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error({ error });
		DEBUGGING && console.groupEnd();

		return error;
	}
};

export const SelectionsService = {
	fetchRestaurantSelections,
};
