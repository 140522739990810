import React, { ReactNode } from "react";
import styled from "styled-components";
import { flex } from "Styles/tools";
import { Logo } from "Components/Shared";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { AppThemeColors } from "Styles/Theme";
import { AnimatePresence, motion } from "framer-motion";
import { Subtitle } from "Components/Core";

const LogoContainer = styled(motion.div)`
	${flex("column", "center", "center", "nowrap")}
	width: 100%;
`;

const TextContainer = styled(motion.div)`
	${flex("column", "center", "center", "nowrap")}
	width: 100%;
`;

interface LoadingProps {
	logo?: boolean;
	size?: string;
	color?: AppThemeColors;
	text?: boolean;
	customText?: ReactNode;
}

const VARIANTS_LOGO = {
	in: {
		scale: [1, 1.15],
	},
};

const VARIANTS_TEXT = {
	in: {
		opacity: 0.6,
	},
};

const Loading: React.FC<LoadingProps> = ({
	size,
	logo = false,
	color = "black",
	text = true,
	customText,
}: LoadingProps) => {
	const { t } = useTranslation();

	return (
		<AnimatePresence>
			<>
				<LogoContainer
					className="loader-container-logo"
					animate="in"
					transition={{
						repeat: Infinity,
						repeatType: "reverse",
					}}
					variants={VARIANTS_LOGO}
				>
					<Row>
						<Col className={`mx-auto`}>{logo && <Logo size={size} />}</Col>
					</Row>
				</LogoContainer>
				<TextContainer
					className="loader-container-text"
					animate="in"
					transition={{
						duration: 1,
						repeat: Infinity,
						repeatType: "reverse",
					}}
					variants={VARIANTS_TEXT}
				>
					{text && <Subtitle color={color}>{`${customText ? customText : `${t("common.loading")}...`}`}</Subtitle>}
				</TextContainer>
			</>
		</AnimatePresence>
	);
};

export default Loading;
