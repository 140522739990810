import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { HTTP_STATUS } from "Types";
import { SelectionsService } from "../Services/Selections";
import { ASYNC_FAILED, ASYNC_IDLE, ASYNC_LOADING, ASYNC_SUCCEEDED } from "Utils/constants/thunks";

export interface SelectionsState {
	data: any;
	error: string | null;
	status: HTTP_STATUS;
}

const InitSelectionsState: SelectionsState = {
	data: [],
	error: null,
	status: ASYNC_IDLE,
};

/* GET SELECTIONS DATA THUNK  */
export const getRestaurantSelections = createAsyncThunk<any, any, {}>(
	"selections/getSelectionsData",
	async (payload, { rejectWithValue }) => {
		try {
			const { clientName } = payload;
			const response = await SelectionsService.fetchRestaurantSelections(clientName);

			// If process failed (and backend sent us 200 status), we throw an error to dispatch a rejected status on redux
			if (response.info && response.info === "error") throw response;

			return response.selections;
		} catch (error) {
			// Dispatch rejected status on redux with error payload
			return rejectWithValue({ message: (error as any).response.msg });
		}
	}
);

/* REDUCER CREATOR */
const SelectionsSlice = createSlice({
	name: "selections",
	initialState: InitSelectionsState,
	reducers: {},
	extraReducers: {
		[getRestaurantSelections.pending.type]: (state): void => {
			state.status = ASYNC_LOADING;
		},
		[getRestaurantSelections.fulfilled.type]: (state, action: PayloadAction<any>): void => {
			state.status = ASYNC_SUCCEEDED;
			state.data = action.payload.filter(({ enabled }: any) => enabled);
		},
		[getRestaurantSelections.rejected.type]: (state, action: PayloadAction<any>): void => {
			state.status = ASYNC_FAILED;
			state.error = `${action.payload ? action.payload.message : ""}`;
		},
	},
});

/* ACTION TYPES EXPORTS */
// export const {} = SelectionsSlice.actions;

export default SelectionsSlice;
