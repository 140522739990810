/* eslint-disable @typescript-eslint/camelcase */
import { fetchDishes } from "../Services/Dishes";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store";
import { setDishesActionCreator } from "../Reducers/Dishes";
import { getDishGlobalCategories, getDishSelfCategories } from "../Reducers/Categories";
import { filterDishesByRestaurant } from "Utils";

export default function useFetchDishes(clientid: string, page: number, menuFilters: any, restaurantId: string, skipRequest = false) {
	const dishes = useSelector((store: AppState) => store.dishes);
	const clientUsername = useSelector((store: AppState) => store.client.data?.clientid);
	const clientType = useSelector((store: AppState) => store.client.data?.client_type);
	const dispatch = useDispatch();

	const [fetchingDishGlobalCategories, setFetchingDishGlobalCategories] = useState(false);
	const [fetchingDishCategoriesData, setFetchingDishCategoriesData] = useState(false);
	const [dishError, setError] = useState(false);
	const [hasMoreDishes, setHasMoreDishes] = useState(false);
	const [fetchingDishes, setFetchingDishes] = useState(false);

	useEffect(() => {
		const getDishes = async () => {
			if (skipRequest || !clientid) return;

			try {
				setFetchingDishes(true);
				setError(false);

				const { pairings, results_number } = await fetchDishes(clientid, page, menuFilters, restaurantId);
				const filteredDishesByRestaurant = filterDishesByRestaurant(clientType ?? "", restaurantId, pairings ?? []);

				const parsedList = pairings;
				const dishesInStore = dishes.data ? dishes.data : [];

				dispatch(
					setDishesActionCreator({
						data: [...dishesInStore, ...parsedList],
						dishesTotal: filteredDishesByRestaurant.length,
						error: null,
					})
				);
				setFetchingDishes(false);
				setHasMoreDishes(dishes.data ? dishes.data.length < results_number : true);
			} catch (e) {
				setError(true);
				setFetchingDishes(false);
				console.log(e);
			}
		};
		!dishes.data && getDishes();
	}, [page, clientid, menuFilters, dishes.data, restaurantId, dispatch, clientType, skipRequest]);

	const fetchDishGlobalCategories = useCallback(async () => {
		if (!clientUsername) return;
		await dispatch(getDishGlobalCategories({ clientUsername }));
		setFetchingDishGlobalCategories(false);
	}, [dispatch, clientUsername]);

	const fetchSelfDishCategories = useCallback(async () => {
		if (!clientUsername) return;
		await dispatch(getDishSelfCategories({ clientUsername }));
		setFetchingDishCategoriesData(false);
	}, [dispatch, clientUsername]);

	return {
		dishError,
		dishes,
		fetchingDishes,
		hasMoreDishes,
		fetchDishGlobalCategories,
		fetchingDishGlobalCategories,
		fetchSelfDishCategories,
		fetchingDishCategoriesData,
	};
}
