import { ASYNC_IDLE, ASYNC_LOADING, ASYNC_SUCCEEDED, ASYNC_FAILED } from "Utils/constants/thunks";

export const ClientType = {
	HOTEL: "hotel",
	RESTAURANT: "restaurant",
	DISTRIBUTOR: "distributor",
};

export const Currencies = {
	USD: "USD",
	MXN: "MXN",
	EUR: "EUR",
	BRL: "BRL",
};

export type ActionBarType = "restaurant" | "cellar" | "menu" | "drinks";
export const ACTION_BAR_TYPES = {
	RESTAURANT: "restaurant",
	CELLAR: "cellar",
	MENU: "menu",
	DRINKS: "drinks",
};

export type OptionType = { label: string; value: string };

export type Wine = {
	archived: boolean;
	available: boolean;
	byGlass: boolean;
	cost: string;
	country: string;
	countryEN: string;
	countryPT: string;
	descEN: string;
	descES: string;
	descPT: string;
	discount: number;
	earning: number;
	glassEarning: number;
	glassPrice: number;
	glasses_num: number;
	hostNotesEN: string;
	hostNotesES: string;
	hostNotesPT: string;
	house: string;
	houseEN: string;
	housePT: string;
	id: number;
	image: string;
	lastSelled: number;
	nameEN: string;
	nameES: string;
	namePT: string;
	outOfStock: boolean;
	region: string;
	regionEN: string;
	regionPT: string;
	sellPrice: number;
	size: number;
	stock: number;
	subregion: string;
	subregionEN: string;
	subregionPT: string;
	suggested: boolean;
	typeEN: string;
	typeES: string;
	typePT: string;
	varietalEN: string;
	varietalES: string;
	varietalPT: string;
	vin: string;
	year: number;
	exportManager: any[];
	cup_format: any[];
};

export type WineCategoriesType = {
	countryEN: string[];
	countryES: string[];
	countryPT: string[];
	regionEN: string[];
	regionES: string[];
	regionPT: string[];
	subregionEN: string[];
	subregionES: string[];
	subregionPT: string[];
	total?: number;
	by_glass?: number;
	typeEN: string[];
	typeES: string[];
	typePT: string[];
	varietalEN: string[];
	varietalES: string[];
	varietalPT: string[];
};

export type Restaurant = {
	_id: string;
	active: boolean;
	descEN: string;
	descES: string;
	descPT: string;
	name: string;
	qr_hash?: string;
	qr_url?: string;
};

export type Dish = {
	categories: number[];
	dish_desc_en: string;
	dish_desc_es: string;
	dish_desc_pt: string;
	dish_img: string | null;
	dish_name_en: string;
	dish_name_es: string;
	dish_name_pt: string;
	dish_price: number | null;
	id: string;
	is_breakfast: boolean;
	is_dinner: boolean;
	is_lunch: boolean;
	label: string;
	outOfStock: boolean;
	selfcategory: ISelfCategory;
	wines: number[];
	restaurants: Restaurant[];
};

export type ISelfCategory = {
	id: number;
	nameEN: string;
	nameES: string;
	namePT: string;
	superId: number;
	order: number;
};

export type GlobalCategory = {
	Id: number;
	_id: string;
	name_en: string;
	name_es: string;
	name_pt: string;
	order: number;
};

export type DishSelfCategory = {
	Id: number;
	_id: string;
	name_en: string;
	name_es: string;
	name_pt: string;
	superId: number;
	category: string;
	order: number;
};

export type DrinkSelfCategory = {
	Id: number;
	_id: string;
	name_en: string;
	name_es: string;
	name_pt: string;
	drinkcategory: string;
	img_url: string;
	order: number;
};

export type Drink = {
	_id: string;
	drink_id: number;
	descEN: string;
	descES: string;
	descPT: string;
	drinkcategory: string;
	img_url: string;
	nameEN: string;
	nameES: string;
	namePT: string;
	outOfStock: boolean;
	price: number;
	selfdrinkcategories: string[];
	unit: string;
	volume: number;
	restaurants: Restaurant[];
};

export type ClientURL = {
	restaurantId: string;
	clientId: string;
	sectionId?: string;
	itemId?: string;
	wineId?: string;
};

export type CellarOrder = "name" | "price" | "size" | "vin";

export type ScheduleOptions = "is_breakfast" | "is_lunch" | "is_dinner";

export type HTTP_STATUS = typeof ASYNC_IDLE | typeof ASYNC_LOADING | typeof ASYNC_SUCCEEDED | typeof ASYNC_FAILED;
