import styled from "styled-components";
import { MdLocalBar } from "react-icons/md";
import { AppThemeColors } from "Styles/Theme";
import { ReactComponent as Restaurantes } from "Assets/icons/Restaurantes.svg";
import { ReactComponent as Cellar } from "Assets/icons/WINE.svg";
import { ReactComponent as Dishes } from "Assets/icons/Maridaje.svg";
import { ReactComponent as Glass } from "Assets/icons/Copa.svg";
import { ReactComponent as Filter } from "Assets/icons/Filtro.svg";
import { ReactComponent as Facebook } from "Assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "Assets/icons/twitter.svg";
import { ReactComponent as Youtube } from "Assets/icons/youtube.svg";
import { ReactComponent as Linkedin } from "Assets/icons/linkedin.svg";
import { ReactComponent as Instagram } from "Assets/icons/instagram.svg";

type IconType = {
	color?: AppThemeColors;
};

export function transitionAndSize() {
	return `transition: fill 250ms ease; font-size: 1.5em;`;
}

export const PlaceIcon = styled(Restaurantes)<IconType>`
	* {
		${transitionAndSize()}
		fill: ${({ theme, color = "black" }) => theme.colors[color]}!important;
	}
`;

export const DrinksIcon = styled(MdLocalBar)<IconType>`
	color: ${({ theme, color = "black" }) => theme.colors[color]};
	${transitionAndSize()}
`;

export const MenuIcon = styled(Dishes)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const CellarIcon = styled(Cellar)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const GlassIcon = styled(Glass)<IconType>`
	width: 0.7em;
	margin-right: 5px;
	* {
		fill: ${({ theme, color = "blue" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const FiltersIcon = styled(Filter)<IconType>`
	padding-left: 20px;
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const FacebookIcon = styled(Facebook)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const TwitterIcon = styled(Twitter)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const YoutubeIcon = styled(Youtube)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const LinkedinIcon = styled(Linkedin)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;

export const IntagramIcon = styled(Instagram)<IconType>`
	* {
		fill: ${({ theme, color = "black" }) => theme.colors[color]};
		${transitionAndSize()}
	}
`;
