import React from 'react'
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";

import { flex } from '../../Styles/tools';
import { motion } from 'framer-motion';
import { AppThemeColors } from '../../Styles/Theme';

type ArrowIconType = {
  textColor?: AppThemeColors;
}
const ArrowToggleContainer = styled(motion.div)`
  ${ flex('row', 'center', 'center', 'nowrap')};
  padding-right: 3px;

`

interface IArrowToggleProps {
  open: boolean;
  textColor?: AppThemeColors;
  style?: any;
}
const ArrowToggle: React.FC<IArrowToggleProps> = ({open, textColor = 'white', style}: IArrowToggleProps) => {
  return (
    <ArrowToggleContainer animate={{rotate: open ? 180 : 0, x: open ? -4 : 0}} className='arrow-toggle-container'>
      <IoIosArrowDown  style={{color: textColor,  fontSize: '1.3em',  paddingTop: '3px', ...style}}/>
    </ArrowToggleContainer>
  )
}

export default ArrowToggle
