/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import VizSensor from "react-visibility-sensor";

import { ClientURL, Wine } from "Types";
import CellarFilters from "./CellarFilters";
import { ListContainer, ListItem, FlexContainer, Loading } from "Components/Shared";
import { AppState } from "store";
import { useProcessQueryParams } from "Hooks";
import WineListItem from "./WineListItem";
import WineDetailModal from "./WineDetailModal";
import { setCellarFiltersActionCreator } from "Reducers/Filters";
import { resetPageActionCreator } from "Reducers/Wines";
import { useTranslation } from "react-i18next";
import { FilterTitle } from "Components/Core";
import { isUsingCellarFilters } from "Utils";
import { getCellarData } from "Reducers/Categories";
import { getRestaurantSelections } from "Reducers/Selections";
import { ASYNC_SUCCEEDED } from "Utils/constants/thunks";

const CellarHeader = styled.div`
	background-color: ${({ theme }): any => theme.colors.white};
	padding: 10px 0;
	position: absolute;
	width: 100%;
`;

const SectionTitle = styled.h1`
	padding-left: 10px;
	margin: 0.6em 0;
	font-size: 2em;
	text-transform: capitalize;
`;

const SearchContainer = styled.div`
	text-align: center;
	padding-top: 5rem;
`

const SearchInput = styled.input`
	width: 94%;
	margin: 0 auto;
	display: inline-block;
	height: 30px;
	margin-bottom: 10px;
	border-color: #ddd;
	border-style: solid;
	border-radius: 5px;
	padding-left: 10px;
`

const WINES_STEPS_PAGE = 7;

const RestaurantCellar: React.FC = () => {
	const { clientId, restaurantId, sectionId } = useParams<ClientURL>();
	const history = useHistory();
	const { t } = useTranslation();

	const filters = useSelector((state: AppState) => state.filters.cellar);
	const client = useSelector((state: AppState) => state.client.data);
	const selections = useSelector((state: AppState) => state.selections);
	const isUsingFilters = isUsingCellarFilters(filters);
	const isCellarLoaded = useSelector((state: AppState) => state.wines.status === "Ok");

	const [shownFilterBadge, setShownFilterBadge] = useState<boolean>(false);
	const [winesToShow, setWinesToShow] = useState(WINES_STEPS_PAGE);
	const { search } = useLocation(); // search contains optional query params
	const dispatch = useDispatch();
	const wines = useSelector((store: AppState) => store.wines);
	const { homePage } = useProcessQueryParams();
	const [searchInput, setSearchInput] = useState("");
	const idTimeout = useRef<number | null>(null);

	function onInputSearchTerm(e: any){
		const {value} = e.target;
		if(idTimeout.current) clearTimeout(idTimeout.current)

		idTimeout.current = setTimeout(() => {
			setSearchInput(value);
		}, 200)
	}

	function applySearchFilter(w: Wine){
		if(searchInput.trim() === "") return wines;
		
		return w.nameEN.toLowerCase().includes(searchInput.toLowerCase().trim()) || w.nameES.toLowerCase().includes(searchInput.toLowerCase().trim()) || w.namePT.toLowerCase().includes(searchInput.toLowerCase().trim())
	}

	const handleFilterChange = (name: string, value: string): void => {
		dispatch(resetPageActionCreator());
		dispatch(setCellarFiltersActionCreator({ ...filters, [name]: value }));
	};

	const openWineDetail = (wine: Wine): void => {
		if (client?.client_type === "hotel") {
			history.push(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId ?? homePage}/${wine.id}${search}`);
		} else {
			history.push(`/restaurant/${clientId}/${sectionId ?? homePage}/${wine.id}${search}`);
		}
	};

	const closeWineDetail = (): void => {
		if (client?.client_type === "hotel") {
			history.replace(`/hotel/${clientId}/restaurant/${restaurantId}/${sectionId ?? homePage}`);
		} else {
			history.replace(`/restaurant/${clientId}/${sectionId ?? homePage}`);
		}
	};

	// * STATE MANAGEMENT

	useEffect(() => {
		setShownFilterBadge(isUsingFilters);
	}, [isUsingFilters]);

	useEffect(() => {
		return (): void => {
			dispatch(resetPageActionCreator());
		};
	}, [dispatch]);

	useEffect(() => {
		const isUsingFilters = isUsingCellarFilters(filters);

		if (!client?.clientid) return;
		if (isUsingFilters) return;

		dispatch(getCellarData(client.clientid));
	}, [client, filters, dispatch]);

	useEffect(() => {
		// If a previous successful request was made, we skip request again Selections
		if (selections.data.length > 0 || selections.status === ASYNC_SUCCEEDED) return;

		// If something fails, make a new request with "clientId" updated.
		// This may happen if on WelcomeView the request fails due "clientId" is client_id instead of client_name because of legacy old syntaxis
		if(selections.status === "IDLE"){
			dispatch(getRestaurantSelections({ clientName: clientId }));
		}
	}, [clientId, dispatch, selections.data.length, selections.status]);

	// * COMPONENT RENDERING
	if (isCellarLoaded && !wines.data?.length && !isUsingFilters) {
		return (
			<>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{ height: "100%", flex: 1 }}
				>
					{/* No data found message */}
					<ListContainer padding="0.5rem">
						<FlexContainer direction="column" justify="center" width="90vw">
							<FilterTitle>{t("common.noDataFound")}</FilterTitle>
						</FlexContainer>
					</ListContainer>
				</motion.div>
			</>
		);
	}

	const filteredWines = wines.data?.filter(applySearchFilter) ?? [];

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				style={{ flex: 1, height: "86vh" }}
			>
				<CellarHeader className="cellar-header">
					<CellarFilters
						filtersState={filters}
						handleFilterChange={handleFilterChange}
						loading={!isCellarLoaded}
						customWinesTotal={filteredWines.length}
					/>
				</CellarHeader>

				<SearchContainer style={{paddingTop: shownFilterBadge ? "7rem" : "5rem"}}>
					<SearchInput type="text" onInput={onInputSearchTerm} placeholder={t("common.searchByName")} />
				</SearchContainer>

				{wines.data ? (
					<>
						{/* No data found message */}
						{filteredWines.length === 0 ? (
							<ListContainer
								className={`no-labels-available`}
								padding={`${shownFilterBadge ? "0.25rem 0.5rem 0.5rem 0.5rem" : "0.25rem 0.5rem 0.5rem 0.5rem"}`}
							>
								<FlexContainer direction="column" justify="center" width="90vw">
									<SectionTitle>{t("common.noLabelsAvailable")}</SectionTitle>
								</FlexContainer>
							</ListContainer>
						) : (
							<ListContainer
									padding={`${shownFilterBadge ? "0.25rem 0.5rem 0.5rem 0.5rem" : "1.25rem 0.5rem 0.5rem 0.5rem"}`}
								>
									{filteredWines &&
										filteredWines.slice(0, winesToShow).map((wine: Wine, index: number) => (
											<ListItem onClick={(): void => openWineDetail(wine)} key={index}>
												<WineListItem wine={wine} />
											</ListItem>
										))}
									<VizSensor
										partialVisibility
										onChange={appendWines}
									>
										<FlexContainer direction="column" justify="center" width="100%">
											<Loading show={isCellarLoaded && filteredWines.length - winesToShow >= 0} logo size="80px" textSize="0.9rem" padding="0.5rem 0" />
										</FlexContainer>
									</VizSensor>
								</ListContainer>
						)}
					</>
				) : (
					<Loading show={!isCellarLoaded} logo={true} />
				)}
			</motion.div>
			<WineDetailModal close={closeWineDetail} />
		</>
	);

	function appendWines(isVisible: boolean): false | void{
		if(isVisible)
			setWinesToShow(winesToShow + WINES_STEPS_PAGE);
	}
};

export default RestaurantCellar;
