import axios from "axios";
import { PUBLIC_TOKEN, URL_INTEGRAL } from "env";

const SERVICE_ID = "@RestaurantService";
const DEBUGGING = false;

const fetchDishGlobalCategories = async (clientName: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchDishGlobalCategories`);

	try {
		const response = await axios.get(`${URL_INTEGRAL}/${clientName}/categories/${PUBLIC_TOKEN}`);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error({ error });
		DEBUGGING && console.groupEnd();

		return [];
	}
};

const fetchSelfDishCategories = async (clientName: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchSelfDishCategories`);
	try {
		const response = await axios.get(`${URL_INTEGRAL}/${clientName}/selfcategories/${PUBLIC_TOKEN}`);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error({ error });
		DEBUGGING && console.groupEnd();

		return error;
	}
};

const fetchDrinkGlobalCategories = async (clientName: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchDrinkGlobalCategories`);

	try {
		const response = await axios.get(`${URL_INTEGRAL}/${clientName}/drinkcategories/${PUBLIC_TOKEN}`);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error({ error });
		DEBUGGING && console.groupEnd();

		return [];
	}
};

const fetchSelfDrinkCategories = async (clientName: string, restaurantId: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchSelfDrinkCategories`);
	try {
		DEBUGGING && console.log({ params: { restaurantId } });
		const response = await axios.get(
			`${URL_INTEGRAL}/${clientName}/selfdrinkcategories/${PUBLIC_TOKEN}?restaurant_id=${restaurantId}`
		);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (e) {
		DEBUGGING && console.error(`${SERVICE_ID}:fetchSelfDrinkCategories`, e);
		DEBUGGING && console.groupEnd();

		return e;
	}
};

const fetchCellarData = async (clientId: string): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID} => fetchCellarData`);
	try {
		const CONFIG = { headers: { authorization: PUBLIC_TOKEN } };
		const response = await axios.get(`${URL_INTEGRAL}/clients/${clientId}/wines-info`, CONFIG);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error({ error });
		DEBUGGING && console.groupEnd();

		return error;
	}
};

export const RestaurantService = {
	fetchDishGlobalCategories,
	fetchSelfDishCategories,
	fetchDrinkGlobalCategories,
	fetchSelfDrinkCategories,
	fetchCellarData,
};
