type Direction = "column" | "row" | "unset";
export type Justify =
	| "center"
	| "flex-start"
	| "flex-end"
	| "space-between"
	| "space-evenly"
	| "space-around"
	| "unset";
type Align = "center" | "flex-start" | "flex-end" | "baseline" | "unset";
type Wrap = "wrap" | "nowrap" | "wrap-reverse" | "unset";

export function flex(direction: Direction, justify: Justify, align: Align, wrap: Wrap): string {
	return `
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
    flex-wrap: ${wrap};
  `;
}
