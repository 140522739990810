const DEFAULT_CURRENCY_DIGITS = 2;

const formatCurrencyAmount = (
  amount: number,
  currency = "",
  digits: number = DEFAULT_CURRENCY_DIGITS
) =>{

  const CURRENCY_DICT = {
    MXN: "es-MX",
    USD: "en-US",
    EUR: "es",
    BRL: "pt-BR",
    PEN: "es-PE",
    COP: "es-CO",
  };

  const language = CURRENCY_DICT[currency as keyof typeof CURRENCY_DICT] ?? "en-US";
  let formatterCurrency =  new Intl.NumberFormat(
    language, 
    {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: digits,
    }
    ).format(amount);
    let showCurrency = currency === "PEN" || currency === "COP"? "": currency;

    let formatter = "".concat(formatterCurrency, " ", showCurrency);
    return formatter

}

export default formatCurrencyAmount;
