import React from 'react';
import styled from 'styled-components';
import  { MdSearch } from 'react-icons/md'

import { AppTheme } from '../../../Styles/Theme';
import { flex } from '../../../Styles/tools';

type InputTypes = {
  width?: string;
  margin?: string;
  padding?: string;
}

const InputContainer = styled.div<InputTypes>`
  ${ flex('row', 'flex-start', 'center',  'nowrap') }
  background-color: ${ ({theme}) => theme.colors.transparent};
  width: ${ ({width = '100%'}) => width};
  margin: ${ ({margin = '0'}) => margin};
  padding: ${ ({padding = '7px'}) => padding};
  border-radius: ${ ({theme}) => theme.radius};
`;

const StyledInput = styled.input`
  border: 0;
  background-color: transparent;
  color: ${ ({theme}) => theme.colors.white};
  outline: 0;
  flex-grow: 10;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder{
    color: ${ ({theme}) => theme.colors.placeholder};
  }
`;

const IconContainer = styled.span`
  margin-right: 5px;
  flex-grow: 0;
`;

interface IInputProps {
  width?: string;
  margin?: string;
  padding?: string;
};

const index: React.FC<IInputProps & React.HTMLProps<HTMLInputElement>> = (props) => {
  const {
    name,
    placeholder,
    value,
    disabled,
    onChange,
    width,
    padding,
    margin,
  } = props;

  return (
    <InputContainer className='input-container' 
      width={width} 
      padding={padding} 
      margin={margin}
    >
      <IconContainer>
        <MdSearch style={{fontSize: '1.1em', marginTop: '24%', color: AppTheme.colors.placeholder}}/>
      </IconContainer>
      <StyledInput className='input' 
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </InputContainer>
  )
}

export default index
