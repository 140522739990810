import React, { ReactNode } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { use100vh } from "react-div-100vh";

import { flex } from "Styles/tools";

interface PageProps {
	animated?: boolean;
	children: ReactNode;
	customHeight?: number;
}

const SuperContainer = styled.div<any>`
	width: 100%;
	height: ${({ height }) => height}px;
`;

const PageContainer = styled(motion.div)`
	${flex("column", "flex-start", "flex-start", "nowrap")}
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.white};
`;

const PAGE_VARIANTS = {
	initial: {
		x: 100,
	},
	in: {
		x: 0,
	},
	out: {
		x: -100,
	},
};

const Page: React.FC<PageProps> = ({ animated = true, children, customHeight = 100 }) => {
	const height = use100vh();
	const partialHeight = height ? (customHeight / 100) * height : `${customHeight}vh`;

	return (
		<SuperContainer height={partialHeight}>
			<PageContainer
				className="page-container"
				initial="initial"
				animate="in"
				exit="out"
				transition={{ duration: 0.25 }}
				variants={animated ? PAGE_VARIANTS : {}}
			>
				{children}
			</PageContainer>
		</SuperContainer>
	);
};

export default Page;
