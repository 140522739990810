import { useCallback } from 'react';
import { getClientData } from '../Reducers/Client';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';

export default function useFetchClientData(clientId: string, restaurantId: string) {
  const dispatch = useDispatch();
  const isFetchingData = useSelector((state: AppState) => state.client.isLoading);

  const fetchData = useCallback(async () =>  {
    await dispatch(getClientData(clientId));
  }, [clientId, dispatch]);

  return { fetchingData: isFetchingData, fetchData };
}