import React from "react";
import styled from "styled-components";

const FilterSubTitle = styled.h2`
	padding-left: 10px;
	margin: 0.5em 0 0 0;
	font-size: 1.25rem;
	font-weight: lighter;
	font-family: CenturyGothicBold;
	color: ${({ theme }) => theme.colors.gray};
	text-transform: capitalize;
`;

const index = ({ children }: any) => {
	return <FilterSubTitle>{children}</FilterSubTitle>;
};

export default index;
