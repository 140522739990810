import axios from "axios";
import { PUBLIC_TOKEN, URL_INTEGRAL } from "env";

const SERVICE_ID = "@DrinksService";
const DEBUGGING = false;

export const fetchDrinks = async (
	clientid: string,
	page: number,
	menuFilter: any,
	restaurantId: string
): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID}`);
	try {
		DEBUGGING && console.log({ params: { clientid, page, menuFilter, restaurantId } });

		const response = await axios.get(`${URL_INTEGRAL}/${clientid}/drinks/${PUBLIC_TOKEN}?outOfStock=false`);

		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error(`${SERVICE_ID}: `, error);
		DEBUGGING && console.groupEnd();

		return error;
	}
};

export const DrinksService = {
	fetchDrinks,
};
