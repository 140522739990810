import React, { useState, FormEvent, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Page, PageHeader, Title } from "Components/Shared";
import { Input } from "Components/Core";
import { useDebounce, useProcessQueryParams } from "Hooks";
import { useTranslation } from "react-i18next";
import RestaurantsList from "./RestaurantsList";
import { useSelector } from "react-redux";
import { AppState } from "store";
import PageContent from "Components/Shared/Page/PageContent";
import { ClientURL } from "Types";

interface HotelViewProps {
	isBannerOpen: boolean;
}

const HotelView: React.FC<HotelViewProps> = ({ isBannerOpen }) => {
	const client = useSelector((state: AppState) => state.client.data);
	const history = useHistory();
	const { clientId } = useParams<ClientURL>();
	const { search, sourceURL, goBackOriginPage } = useProcessQueryParams();

	if (!client) history.push(`/${clientId}${search}`);

	const [searchTerm, setsearchTerm] = useState("");
	const debouncedSearchTerm = useDebounce(searchTerm, 250);
	const [restaurants, setrestaurants] = useState([]);

	const { t } = useTranslation();

	const handleSearchChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => setsearchTerm(value);

	const restaurantClick = (id: string) => history.push(`/hotel/${clientId}/restaurant/${id}${search}`);

	useEffect(() => {
		setrestaurants(client!.restaurants.filter((restaurant: any) => restaurant.name.includes(debouncedSearchTerm)));
	}, [client, debouncedSearchTerm]);

	return (
		<Page customHeight={isBannerOpen ? 92 : 100}>
			<PageHeader
				color="red"
				textColor="white"
				backText={sourceURL ? t("common.goBack") : ""}
				backEnabled={sourceURL ? true : false}
				customBackAction={sourceURL ? goBackOriginPage(sourceURL) : undefined}>
				<Title margin="0 0 10px 0">{client?.description}</Title>
				<Input
					name="searchTerm"
					placeholder={`${t("common.search")} ${t("common.restaurants").toLocaleLowerCase()}`}
					value={searchTerm}
					onChange={handleSearchChange}
				/>
			</PageHeader>
			<PageContent>
				<h6 style={{ padding: "0 16px", margin: "1em 0" }}>{t("hotel-view.available-restaurants")}:</h6>
				{restaurants.length ? (
					<RestaurantsList restaurants={restaurants} onClick={restaurantClick} />
				) : (
					<h6 style={{ padding: "0 20px", margin: "1em 0" }}>{t("hotel-view.noResults")}</h6>
				)}
			</PageContent>
		</Page>
	);
};

export default HotelView;
