import React from "react";
import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { flex } from "../../../../Styles/tools";
import FilterItem from "./FilterItem";
import CloseIconButton from "../../../../Components/Shared/CloseIcon";
import { useTranslation } from "react-i18next";

const Overlay = styled(motion.div)`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	padding: 25px;
	background-color: ${({ theme }): any => theme.colors.overlay};
	${flex("column", "flex-start", "flex-start", "nowrap")}
`;

const ModalContent = styled.div`
	margin: 2em 0;
	flex-grow: 1;
	width: 100%;
	overflow-y: scroll;
	${flex("column", "flex-start", "flex-start", "nowrap")}
`;

const IconContainer = styled.div`
	margin-left: auto;
`;

interface CellarFiltersModalProps {
	isOpen: boolean;
	close: () => void;
	onChange: (name: string, value: string) => void;
}
const CellarFiltersModal: React.FC<CellarFiltersModalProps> = ({ isOpen, onChange, close }) => {
	const { t } = useTranslation();

	const CATEGORIES_KEYS_ARRAY = [
		{ label: t("wineDetail.selection"), key: "selection" },
		{ label: t("wineDetail.glass"), key: "selling_by" },
		{ label: t("wineDetail.type"), key: "type" },
		{ label: t("wineDetail.variety"), key: "varietal" },
		{ label: t("wineDetail.country"), key: "country" },
		{ label: t("wineDetail.region"), key: "subregion" },
	];

	const filterClicked = (name: string, value: string): void => {
		onChange(name, value);
		close();
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<Overlay
					className="overlay"
					initial={{ x: -500 }}
					animate={{ x: 0 }}
					exit={{ x: -500 }}
					transition={{ duration: 0.25 }}
				>
					<IconContainer>
						<CloseIconButton onClick={close} />
					</IconContainer>
					<ModalContent className="modal-content">
						{CATEGORIES_KEYS_ARRAY.map((filter, index) => (
							<FilterItem key={index} filter={filter} onClick={filterClicked} />
						))}
					</ModalContent>
				</Overlay>
			)}
		</AnimatePresence>,
		document.getElementById("portal") as Element
	);
};

export default CellarFiltersModal;
