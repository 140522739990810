import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, useHistory, useLocation } from "react-router-dom";

import { FlexContainer, Loader, Logo, Page, SocialBanner } from "Components/Shared";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import useFetchClientData from "Hooks/useFetchClientData";
import { Description } from "Components/Core";
import { ClientURL } from "Types";
import PageContent from "Components/Shared/Page/PageContent";
import { APP_VERSION } from "env";
import { getCellarData } from "Reducers/Categories";
import { getRestaurantSelections } from "Reducers/Selections";

const WelcomeView: React.FC<RouteComponentProps> = () => {
	// * REDUX STATE
	const client = useSelector((state: AppState) => state.client);

	// * LOCAL STATE
	const [userNotFound, setUserNotFound] = useState<boolean>(false);
	const [disabledClient, setDisabledClient] = useState<boolean>(false);

	// * LOCAL HELPERS
	const history = useHistory();
	const { t } = useTranslation();
	const { search } = useLocation(); // search contains optional query params
	const dispatch = useDispatch();

	const { clientId, restaurantId }: ClientURL = useParams<ClientURL>(); // clientId or name on URL
	const queryParams = new URLSearchParams(search); // get query params on original URL
	const clientToSearch = queryParams.get("clientid") || clientId; // set clientid (queryparam) or clientId to dispatch search
	const { fetchData } = useFetchClientData(clientToSearch, restaurantId);
	

	// * STATE MANAGEMENT
	useEffect(() => {
		clientToSearch && fetchData(); // if a client (mongoId or queryparam) is in URL, then fetchData is dispatched
		!clientToSearch && setUserNotFound(true); // if no mongoId for user nor queryparam is in URL, then set user not found to display on UI
	}, [clientToSearch, fetchData]);

	useEffect(() => {
		if (!client.data || !client.data.client_type) return;

		const username = client.data.clientid || clientId;
		const clientType = client.data.client_type ? client.data.client_type : "restaurant";

		// Special case: check if is deadbeat client or is disabled
		const isDisabled = !client.data.habilitado;
		setDisabledClient(isDisabled);
		if (isDisabled) return;

		// Umami tracker utility to manually dispatch register visited client
		(window as any).umami.trackView(`/${username}`);

		// Redirect to its correspondant view
		history.push(`/${clientType}/${username}${search}`); // Redirect to Hotel or Restaurant container (view)
	}, [history, search, clientId, client]);

	useEffect(() => {
		if (!client.data?.clientid) return;
		dispatch(getCellarData(client.data.clientid));
	}, [client.data, dispatch]);

	useEffect(() => {
		dispatch(getRestaurantSelections({ clientName: clientId }));
	}, [clientId, dispatch]);

	// * COMPONENT RENDERING
	if (disabledClient) {
		return (
			<Page>
				<PageContent>
					<FlexContainer width="100%" height="100%" direction="column" justify="center" align="center">
						<Logo size="200px" />
						<Description margin="0.5rem 0">{`v${APP_VERSION}`}</Description>
						<Description margin="0.5rem 0">{t("common.userDisabled")}</Description>
						<SocialBanner />
					</FlexContainer>
				</PageContent>
			</Page>
		);
	}

	return (
		<Page>
			<PageContent>
				<FlexContainer width="100%" height="100%" direction="column" justify="center" align="center">
					{!client.error && !userNotFound && <Loader customText={`v${APP_VERSION}`} logo text size="150px" />}
					{(client.error || userNotFound) && (
						<>
							<Logo size="200px" />
							<Description margin="0.5rem 0">{`v${APP_VERSION}`}</Description>
							<Description margin="0.5rem 0">{t("common.userNotFoundError")}</Description>
							<SocialBanner />
						</>
					)}
				</FlexContainer>
			</PageContent>
		</Page>
	);
};

export default WelcomeView;
