import React, { useCallback, useEffect, useState } from "react";
import { Select } from "..";
import { useTranslation } from "react-i18next";
import { AppThemeColors } from "Styles/Theme";
import { AppState } from "store";
import { useSelector } from "react-redux";

interface ILanguageSelectProps {
  textColor: AppThemeColors;
}

const LANG_LIST = [
  { label: "ES", value: "es", default: true, id: "spanish" },
  { label: "EN", value: "en", default: true, id: "english" },
  { label: "PT", value: "pt", default: false, id: "portuguese" },
];
const LanguageSelect: React.FC<ILanguageSelectProps> = ({
  textColor,
}: ILanguageSelectProps) => {
  const { i18n } = useTranslation();
  const languages = useSelector(
    (store: AppState) => store.client.data?.languages
  );
  const [currentLang, setCurrentLang] = useState(i18n.language.split("-")[0]);

  const DEFAULT_LANGUAGES = LANG_LIST.filter((lang) => lang.default); // Default callback languages when client has not been selected languages on Back Office (empty languages array)
  const AVAILABLE_LANGUAGES = languages?.length ? LANG_LIST.filter(({ id }) => languages?.includes(id)) : DEFAULT_LANGUAGES;

  const updateLanguage = useCallback((value: any) => i18n.changeLanguage(value), [i18n]);

  // Special process when default client language is not into available language options. This allow select second option
  useEffect(() => {
    const availableLangOnCLient = AVAILABLE_LANGUAGES.find(({ value }) => value === currentLang);
    if (availableLangOnCLient) {
      return;
    }
    const alternativeLang = AVAILABLE_LANGUAGES[0];
    updateLanguage(alternativeLang.value);
    setCurrentLang(alternativeLang.value);
  }, [AVAILABLE_LANGUAGES, currentLang, updateLanguage]);

  return (
    <Select
      options={AVAILABLE_LANGUAGES}
      value={currentLang}
      onChange={({ currentTarget }) => updateLanguage(currentTarget.value)}
      verticalOffset={15}
      leftOffset={-7}
      textColor={textColor}
    />
  );
};

export default LanguageSelect;
