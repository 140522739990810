import React, { useEffect } from "react";
import { Description } from "Components/Core";
import { FlexContainer, Logo, Page, SocialBanner } from "Components/Shared";
import PageContent from "Components/Shared/Page/PageContent";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

const ErrorView: React.FC<RouteComponentProps> = () => {
	const { t } = useTranslation();

	useEffect(() => {
		// Umami tracker utility to manually dispatch register visited client
		(window as any).umami.trackView(`/Error`);
	}, []);

	return (
		<Page>
			<PageContent>
				<FlexContainer width="100%" height="100%" direction="column" justify="center" align="center">
					<Logo size="200px" />
					<Description>{t("common.pageNotFound")}</Description>
					<SocialBanner />
				</FlexContainer>
			</PageContent>
		</Page>
	);
};

export default ErrorView;
