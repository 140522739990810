import axios from "axios";
import { IMenuFilters } from "../Reducers/Filters";
import { AUTHORIZATION, URL_INTEGRAL } from "env";

const SERVICE_ID = "@DishesService";
const DEBUGGING = false;

export const fetchDishes = async (
	clientid: string,
	page: number,
	menuFilter: IMenuFilters,
	restaurantId: string
): Promise<any> => {
	DEBUGGING && console.groupCollapsed(`${SERVICE_ID}`);
	DEBUGGING &&
		console.log(`${SERVICE_ID}`, {
			params: {
				clientid,
				page,
				menuFilter,
				restaurantId,
			},
		});
	const CONFIG = { headers: { authorization: `${AUTHORIZATION}` } };

	try {
		const response = await axios.get(`${URL_INTEGRAL}/clients/pairings/${clientid}?&outOfStock=false`, CONFIG);
		DEBUGGING && console.log({ response });
		DEBUGGING && console.groupEnd();

		return response.data;
	} catch (error) {
		DEBUGGING && console.error(`${SERVICE_ID}: `, error);
		DEBUGGING && console.groupEnd();

		return error;
	}
};

export const DishesService = {
	fetchDishes,
};
