import React, { ReactNode } from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

import { flex, Justify } from "Styles/tools";
import { AppTheme, AppThemeColors } from "Styles/Theme";
import { motion } from "framer-motion";

interface ListItemContainerType {
	color?: AppThemeColors;
	margin?: string;
	padding?: string;
	justify?: Justify;
}

interface ListItemProps extends ListItemContainerType {
	onClick?: () => void;
	children: ReactNode;
	hideIcon?: boolean;
}

const ListItemContainer = styled(motion.div)<ListItemContainerType>`
	width: 100%;
	flex-shrink: 0;
	${({ justify = "space-between" }): any => flex("row", justify, "center", "nowrap")};
	border-bottom: 1px solid ${({ theme, color = "transparent" }): any => theme.colors[color]};
	padding: ${({ padding = "10px" }): any => padding};
	margin: ${({ margin = "" }): any => margin};
`;

const IconContainer = styled.div`
	${flex("row", "center", "center", "nowrap")}
`;

const ListItem: React.FC<ListItemProps> = ({
	onClick,
	children,
	color,
	margin,
	justify,
	padding,
	hideIcon = false,
}) => {
	return (
		<ListItemContainer
			color={color}
			margin={margin}
			padding={padding}
			justify={justify}
			className="list-item-container"
			onClick={onClick}
			whileTap={{ backgroundColor: AppTheme.colors.transparent }}>
			{children}
			<IconContainer className="icon-container">
				{!hideIcon && (
					<IoIosArrowForward className="back-icon" style={{ color: AppTheme.colors.gray, fontSize: "1.2em" }} />
				)}
			</IconContainer>
		</ListItemContainer>
	);
};

export default ListItem;
