import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ListContainerType = {
  height?: string;
  padding?: string;
};

const ListContent = styled.div<ListContainerType>`
  width: 100%;
  height: ${ ({height = 'auto'}) => height };
  padding: ${ ({ padding = '0 0.5rem 0.5rem 0.5rem' }) => padding };
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface IListContainerProps extends ListContainerType {
  children: ReactNode;
  className?: string;
};

const ListContainer = React.forwardRef<HTMLInputElement, IListContainerProps>((props, ref) => {
  const { height, children, className, padding } = props;
  return (
    <ListContent ref={ref} className={className ? className : 'list-content'} height={height} padding={padding}>
      {children}
    </ListContent>
  )
});

export default ListContainer
