import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScheduleOptions } from "Types";

export type Option = { label: string; value: string };

export interface ICellarFilters {
	type: string;
	varietal: string;
	country: string;
	region: string;
	subregion: string;
	selling_by: string;
	selection: string;
}

export interface IMenuFilters {
	selfCategory: any;
}

export interface IDrinksFilters {
	selfCategory: any;
}

const InitialFiltersState = {
	cellar: { type: "", varietal: "", country: "", region: "", subregion: "", selling_by: "" },
	menu: { selfCategory: "" },
	drinks: { selfCategory: "" },
	schedule: "",
};

/* REDUCER CREATOR */
export interface IgetDrinkSelfCategories {
	clientId: string;
	restaurantId: string;
}

export interface FiltersState {
	cellar: ICellarFilters;
	menu: IMenuFilters;
	drinks: IDrinksFilters;
}

const filtersSlice = createSlice({
	name: "filters",
	initialState: InitialFiltersState,
	reducers: {
		setCellarFilters: (state, action: PayloadAction<ICellarFilters>) => {
			state.cellar = action.payload;
		},
		setMenuFilters: (state, action: PayloadAction<IMenuFilters>) => {
			state.menu = action.payload;
		},
		setScheduleFilters: (state, action: PayloadAction<ScheduleOptions>) => {
			state.schedule = action.payload;
		},
		setDrinksFilters: (state, action: PayloadAction<IDrinksFilters>) => {
			state.drinks = action.payload;
		},
		resetFilters: state => {
			return { ...InitialFiltersState };
		},
	},
});

/* ACTION TYPES EXPORTS */
export const {
	setCellarFilters: setCellarFiltersActionCreator,
	setMenuFilters: setMenuFiltersActionCreator,
	setScheduleFilters: setScheduleFiltersActionCreator,
	setDrinksFilters: setDrinksFiltersActionCreator,
	resetFilters: resetFiltersActionCreator,
} = filtersSlice.actions;

export default filtersSlice;
