import { NavLink } from 'Components/Core';
import React from 'react';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CookieDescription = styled.div`
  margin: 0.5rem 0;
`;

const CookiesAdvertising: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.allow")}
        cookieName="CookieConsent"
        style={{ background: "#242424", fontSize: "12px", alignItems: "center", flexWrap: "nowrap" }}
        buttonStyle={{ background: "#ff3b30", color: "#fff", fontSize: "12px", borderRadius: "8px" }}
        contentStyle={{ flex: "1 0" }}
        expires={365}
      >
        <CookieDescription>
          {t("cookies.description")}
        </CookieDescription>
        <NavLink href={"https://wineadvisor.mx/terms-&-privacy"} size="12px">
          {t("cookies.cookiePolicy")}
        </NavLink>
      </CookieConsent>
    </>
  )
}

export default CookiesAdvertising
